<template>

    <section class="content">
        <header class="content__title">
            <h1>Withdrawals</h1>
            <small>A list of all withdrawal requisitions</small>
            <div class="actions"></div>
        </header>

        <ToolbarEvents />

        <Placeholder v-if="isLoading" />

        <div class="card" v-else-if="withdrawals.length > 0">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Currency</th>
                            <th>Amount</th>
                            <th>Event</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in withdrawals" :key="i">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item.currency }}</td>
                            <td>{{ item.requested_amount }}</td>
                            <td>{{ item.event_title }}</td>
                            <td>{{ item.date }}</td>
                            <td>{{ item.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <NotFound v-else />
        
    </section>
</template>

<script>
import { collection, getDocs, getFirestore,  query, where, orderBy } from '@firebase/firestore';
import dayjs from 'dayjs';
const db = getFirestore();
export default {
    data(){
        return {
            isLoading: false,
            withdrawals: [],
        }
    },
    created(){
        this.loadWithdrawal();
    },
    methods: {
        onBack(){
            const organizerId = this.$route.params.organizerId;
            this.$router.push('/organizer/' + organizerId);
        },
        async loadWithdrawal(){
            this.isLoading = true;
            const eventId = this.$route.params.eventId;
            const eventRef = collection(db, "requisitions");
            const q = query(
                eventRef, 
                where("event_id", "==", eventId), 
                orderBy("requested_date", "desc")
            );
            const snapshot = await getDocs(q);
            const docs = snapshot.docs.map(doc => {
                const data = doc.data()
                data.id = doc.id
                data.date = dayjs(data.requested_date.toDate()).format('MMMM DD, YYYY');
                return data
            });
            this.withdrawals = docs;
            this.isLoading = false;
        },
    },
}
</script>