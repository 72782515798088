<template>
    <section class="content">
        <header class="content__title">
            <h1>Events</h1>
            <small>Load all events</small>

            <div class="actions">
                <button class="btn btn-success" @click="onAction('add')">
                    <i class="zmdi zmdi-plus"></i>
                    Add Event
                </button>
            </div>
        </header>

        <Placeholder v-if="isLoading" />

        <div class="row" v-else-if="registrations.length > 0">
            <div class="col-md-12">
                <div class="card">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Expiry Date</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in registrations" :key="i">
                                    <td>{{ item.event }}</td>
                                    <td>{{ item.organizer }}</td>
                                    <td>GHS {{ item.price }}</td>
                                    <td>
                                        <button class="mr-2 btn btn-sm btn-warning" @click="onAction('edit', item, i)">
                                            <span>Edit</span>
                                        </button>
                                        <button class="mr-2 btn btn-sm btn-danger" @click="onAction('delete', item, i)">
                                            <span>Delete</span>
                                        </button>
                                        <button class="mr-2 btn btn-sm btn-primary" @click="onAction('download', item, i)" :disabled="isDownloading">
                                            <span>Download</span>
                                        </button>
                                        <button class="mr-2 btn btn-sm btn-secondary" @click="onAction('transactions', item, i)">
                                            <span>Transactions</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <NotFound message="No registrations found" v-else />

        <AddRegistration v-if="show_modal" @close="onAction('close')" :type="registration_type"
            :registration="registration_data" />

    </section>
</template>

<script>
import { collection, deleteDoc, doc, getDocs, getFirestore, orderBy, query, where } from '@firebase/firestore';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import AddRegistration from '../../modals/AddRegistration.vue';

const db = getFirestore();


export default {
    data() {
        return {
            registrations: [],
            isLoading: false,
            placeholder: '/img/placeholder.jpg',
            per_page: 15,
            unsubscribe: null,
            done: 0,
            show_modal: false,
            registration_type: 'add',
            registration_data: {},
            isDownloading: false,
        }
    },
    components: {
        AddRegistration
    },
    mounted() {
        this.onAction('load');
    },
    methods: {
        async onAction(action, item = {}, index = 0) {
            if (action == 'add') {
                this.show_modal = true;
                this.registration_type = 'add';
                this.registration_data = {};
            }
            if (action == 'edit') {
                this.show_modal = true;
                this.registration_type = 'edit';
                this.registration_data = item;
            }
            if (action == 'close') {
                this.show_modal = false;
                this.onAction('load');
            }
            if (action == 'load') {
                try {
                    this.isLoading = true;
                    let r = collection(db, 'registrations');
                    let o = orderBy('created_at', 'desc');
                    let q = query(r, o);
                    let s = await getDocs(q);
                    this.registrations = s.docs.map(item => {
                        let d = item.data();
                        d.id = item.id;
                        return d;
                    });
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isLoading = false;
                }
            }
            if (action == 'delete') {
                this.$swal({
                    title: 'DELETE',
                    text: 'Are you sure you want to delete this registration?',
                    icon: 'warning',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Yes, delete!',
                    cancelButtonText: 'No, cancel!',
                }).then(async ({ isConfirmed }) => {
                    if (isConfirmed) {
                        this.registrations.splice(index, 1);
                        this.$toast.success('Event deleted successfully');
                        const { id } = item;
                        const ref = doc(db, 'registrations', id);
                        await deleteDoc(ref);
                    }
                }).catch((err) => {
                    console.log(err);
                });

            }
            if (action == 'download') {
                try {
                    const { event_id } = item;
                    const flattenObject = (obj, parent = '', res = {}) => {
                        for (let key in obj) {
                            if ([key] in obj) {
                                let propName = parent ? parent + '_' + key : key;
                                if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                                    flattenObject(obj[key], propName, res);
                                } else {
                                    res[propName] = obj[key];
                                }
                            }
                        }
                        return res;
                    };
                    const registrationRequestRef = collection(db, 'registration_requests');
                    const w_1 = where('event_id', '==', event_id);
                    const w_2 = where('payment_status', 'in', ['paid', 'success', 'completed']);
                    this.isDownloading = true;
                    const q = query(registrationRequestRef, w_1, w_2);
                    const { docs, empty } = await getDocs(q);
                    if (!empty) {
                        let results = docs.map(item => {
                            let d = item.data();
                            d.id = item.id;
                            const { telephone, register } = d;
                            let _result = { register, telephone };
                            return flattenObject(_result);
                        });

                        // create excel file
                        const ws = utils.json_to_sheet(results);
                        const wb = utils.book_new();
                        utils.book_append_sheet(wb, ws, 'Sheet1');

                        const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });

                        // Save file
                        const fileName = `Registrations.xlsx`;
                        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
                        saveAs(blob, fileName);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isDownloading = false;
                }
            }
            if (action == 'transactions') {
                this.$router.push(`/registrations/${item.event_id}/transactions`);
            }
        },
    }
}
</script>