<template>
    <section class="content">
        <header class="content__title">
            <h1>Nominations</h1>
            <small>A List of Nominees</small>
            <div class="actions">
                <button class="btn btn-danger" @click="onExport" :disabled="isExporting">
                    Export Data
                    <Spinner v-if="isExporting" />
                </button>
            </div>
        </header>
        <div class="toolbar">
            <div class="toolbar__nav">
                <router-link to="/nominations/t/artistes">Artistes</router-link>
                <router-link to="/nominations/t/albums">Albums</router-link>
                <router-link to="/nominations/t/music-videos">MusicVideos</router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{ totals.artistes }}</h4>
                        <div>Artistes</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{ totals.albums }}</h4>
                        <div>Albums</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{ totals.videos }}</h4>
                        <div>Videos</div>
                    </div>
                </div>
            </div>
        </div>
            
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Photo</th>
                            <th>Artist Name</th>
                            <th>Stage Name</th>
                            <th>Album Title</th>
                            <th>release_date</th>
                            <th>Region</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="type == 'artistes'">
                        <tr v-for="(item, i) in items" :key="i">
                            <td>
                                <img :src="item.artist_photo || placeholder" alt="" class="nominee-image">
                            </td>
                            <td>{{ item.artist_name }}</td>
                            <td>{{ item.stage_name }}</td>
                            <td>{{ item.song_title }}</td>
                            <td>{{ item.region }}</td>
                            <td>{{ item.music_genre }}</td>
                            <td>
                                <button class="btn btn-success" @click="onOpen(item)">
                                    <i class="zmdi zmdi-eye"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="type == 'albums'">
                        <tr v-for="(item, i) in items" :key="i">
                            <td>
                                <img :src="item.album_cover || placeholder" alt="" class="nominee-image">
                            </td>
                            <td>{{ item.artist_name }}</td>
                            <td>{{ item.stage_name }}</td>
                            <td>{{ item.album_title }}</td>
                            <td>{{ item.release_date }}</td>
                            <td>{{ item.artist_region }}</td>
                            <td>
                                <button class="btn btn-success" @click="onOpen(item)">
                                    <i class="zmdi zmdi-eye"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="type == 'music-videos'">
                        <tr v-for="(item, i) in items" :key="i">
                            <td>
                                <img :src="item.album_cover || placeholder" alt="" class="nominee-image">
                            </td>
                            <td>{{ item.artist_name }}</td>
                            <td>{{ item.stage_name }}</td>
                            <td>{{ item.album_title }}</td>
                            <td>{{ item.release_date }}</td>
                            <td>{{ item.artist_region }}</td>
                            <td>
                                <button class="btn btn-success" @click="onOpen(item)">
                                    <i class="zmdi zmdi-eye"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <pre>{{ items[0] }}</pre> -->
        </div>
    </section>
</template>

<script>
import { loadArtistes, loadAlbums, loadMusicVideos, totalArtistes, totalAlbums, totalMusicVideos } from '../../services/Nominations';
import { httpsCallable, getFunctions } from '@firebase/functions';
const functions = getFunctions();
export default {
    data() {
        return {
            placeholder: '/img/placeholder-square.jpg',
            isLoading: false,
            items: [],
            type: 'artistes',
            totals: {
                artistes: 0,
                albums: 0,
                videos: 0,
            },
            isExporting: false,
        }
    },
    mounted(){
        const { nominationType } = this.$route.params
        switch (nominationType) {
            case 'artistes':
                this.onLoadArtistes();
                break;
            case 'albums':
                this.onLoadAlbums();
                break;
            case 'music-videos':
                this.onLoadMusicVideos();
                break;
            default:
                this.items = [];
                break;
        }
        this.onLoadTotals();
    },
    methods: {
        async onLoadArtistes(){
            try {
                this.items = []
                this.isLoading = true;
                const { status, data } = await loadArtistes()
                if (status) {
                    this.items = data
                }
                this.isLoading = false;
            } catch (error) {
                this.items = []
                this.isLoading = false;
            }
        },
        async onLoadAlbums(){
            try {
                this.items = []
                this.isLoading = true;
                const { status, data } = await loadAlbums()
                if (status) {
                    this.items = data
                }
                this.isLoading = false;
            } catch (error) {
                this.items = []
                this.isLoading = false;
            }
        },
        async onLoadMusicVideos(){
            try {
                this.items = []
                this.isLoading = true;
                const { status, data } = await loadMusicVideos()
                if (status) {
                    this.items = data
                }
                this.isLoading = false;
            } catch (error) {
                this.items = []
                this.isLoading = false;
            }
        },
        async onLoadTotals(){
            const list_promises = [  totalArtistes(), totalAlbums(), totalMusicVideos() ]
            Promise
                .all(list_promises)
                .then(response => {
                    const Artists = response[0]
                    const Albums = response[1]
                    const Videos = response[2]
                    this.totals.artistes = Artists.data
                    this.totals.albums = Albums.data
                    this.totals.videos = Videos.data
                })
                .catch(error => console.log(error))
        },
        onViewArtist(item){
            this.$router.push("/nominations/t/artistes/" + item.id).catch(() => {})
        },
        onViewAlbum(item){
            this.$router.push("/nominations/t/albums/" + item.id).catch(() => {})
        },
        onViewMusicVideo(item){
            this.$router.push("/nominations/t/music-videos/" + item.id).catch(() => {})
        },
        onOpen(item){
            const { id } = item;
            const { nominationType } = this.$route.params
            this.$router.push(`/nominations/t/${nominationType}/${id}`).catch(() => {})
        },
        async onExport(){
            this.isExporting = true;
            await httpsCallable(functions, 'exportNominees')({})
            this.$toast.success("Nomination data exported successfully and sent to your email smartkastghana@gmail.com")
            this.isExporting = false;
        }
    },
    watch: {
        $route(to) {
            const { params } = to;
            const { nominationType } = params;
            this.type = nominationType;
            if (nominationType == 'artistes') {
                this.onLoadArtistes()
            } else if (nominationType == 'albums') {
                this.onLoadAlbums()
            } else if (nominationType == 'music-videos') {
                this.onLoadMusicVideos()
            }
        },
    },
}
</script>

<style scoped>
.nominee-image {
    width: 100px;
    height: 100px;
    border-radius: 1%;
}
</style>