<template>
    <section class="content">
        <header class="content__title">
            <h1>Pricing</h1>
            <small>Provide information about the pricing</small>
            <div class="actions"></div>
        </header>

        
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="savePricing">

                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" id="DONATION" value="DONATION" v-model="pricing">
                                    <label class="checkbox__label" for="DONATION">DONATION</label>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="checkbox">
                                            <input type="checkbox" id="SIMPLE" value="SIMPLE" v-model="pricing">
                                            <label class="checkbox__label" for="SIMPLE">SIMPLE</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="number" class="form-control">
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" id="BULK" value="BULK" v-model="pricing">
                                    <label class="checkbox__label" for="BULK">BULK</label>
                                </div>
                                <input type="number" class="form-control">
                            </div>

                            <button class="btn btn-success">
                                <i class="fa fa-save"></i> 
                                Save Nominee
                            </button>

                        </form>
                    </div>
                </div>
                <pre>{{ pricing }}</pre>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            pricing: [
                {
                    enabled: false,
                    type: 'DONATION',
                    price: 0,
                    votes: 0,
                },
                {
                    enabled: false,
                    type: 'DONATION',
                    price: 0,
                    votes: 0,
                },
                {
                    enabled: false,
                    type: 'DONATION',
                    price: 0,
                    votes: 0,
                },
            ],
        }
    },
    methods: {
        
        savePricing(){},
        
        onChange($event){
            const { value, checked } = $event.target;
            if (checked) {
                this.pricing.push({
                    type: value,
                    price: 0,
                    votes: 0,
                });
            } else {
                this.pricing = this.pricing.filter(item => item.type !== value);
            }
        }
    }
}
</script>