<template>
    <div class="login">
        <div class="login__block active" id="l-login">
            <div class="login__block__header">
                <i class="zmdi zmdi-account-circle"></i>
                Please enter your email address to reset password. Please note that a pawword reset link will be sent to your email address.
            </div>
            <form @submit.prevent="login">
                <div class="login__block__body">
                    <div class="form-group form-group--centered">
                        <label>Email Address</label>
                        <input type="text" class="form-control" v-model="email" required>
                        <i class="form-group__bar"></i>
                    </div>
                    <button class="btn btn-success btn-block" type="submit" :disabled="isProcessing">
                        Send Password Reset Link
                        <Spinner v-if="isProcessing" />
                    </button>
                    <button class="btn btn-default btn-block" type="button" @click="loginPage">
                        Login
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { validate } from 'email-validator';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore"
const db = getFirestore();

export default {
    data() {
        return {
            email: "",
            password: "",
            isProcessing: false,
        };
    },
    methods: {
        login(){
            const { email } = this;

            if (email == "") {
                return this.$toast.error("Email is required");
            }

            if (!validate(email)) {
                return this.$toast.error("Invalid email address");
            }

            this.isProcessing = true;
            const auth = getAuth();
            signInWithEmailAndPassword(auth, email)
                .then(async (userCredential) => {
                    const { user } = userCredential;
                    const { uid } = user;
                    const userRef = doc(db, 'users', uid);
                    const snapshot = await getDoc(userRef);
                    const data = snapshot.data();
                    this.$store.dispatch("setUser", data);
                    this.$router.push('/').catch(() => {});
                    this.isProcessing = false;
                })
                .catch((error) => {
                    this.isProcessing = false;
                    const { message } = error;
                    return this.$toast.error(message);
                });

        },
        loginPage(){
            this.$router.push('/login').catch(() => {});
        }
    }
}
</script>