<template>
    <section class="content">
        <header class="content__title">
            <h1>Add Event</h1>
            <small>Provide information about the event</small>
            <div class="actions"></div>
        </header>

        <ToolbarEvents />

        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <form @submit.prevent="updateEvent">
                            <div class="form-group">
                                <label for="">Organizer</label>
                                <Multiselect
                                    v-model="organizer"
                                    :options="organizers"
                                    label="name"
                                    track-by="id"
                                    placeholder="Select an organizer"
                                    @select="onSelectOrganizer"
                                    @remove="onRemoveOrganizer"
                                />
                            </div>

                            <div class="form-group">
                                <label for="">Event Title</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="event.title"
                                    required
                                />
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="date">Date</label>
                                        <Datetime  
                                            v-model="datetime"
                                            input-id="date" 
                                            input-class="form-control"
                                            type="datetime"
                                            :min-datetime="minDate"
                                            format="dd-MM-yyyy hh:mm a"
                                            auto />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="pricing">Event Type</label>
                                        <select
                                            class="form-control"
                                            id="pricing"
                                            v-model="event.type"
                                            @change="onTypeChange"
                                        >
                                            <option
                                                v-for="(type, i) in eventTypes"
                                                :key="i"
                                            >
                                                {{ type }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="event.type == 'CONTEST'">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="pricing">Unit Price</label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            placeholder="0.50"
                                            min="0"
                                            step="any"
                                            required
                                            v-model.number="event.price"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="checkbox">
                                            <input
                                                type="checkbox"
                                                id="bulk-votes"
                                                v-model="
                                                    event.enable_bulk_votes
                                                "
                                            />
                                            <label
                                                class="checkbox__label"
                                                for="bulk-votes"
                                                >Enable Bulk Votes</label
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="event.type == 'CONTEST'">
                                <div class="row">
                                    <div class="col-md-12"></div>
                                    <div
                                        class="col-md-12"
                                        v-if="event.enable_bulk_votes"
                                    >
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label for="pricing"
                                                        >Amount</label
                                                    >
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="pricing"
                                                        >Amount</label
                                                    >
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="pricing"
                                                        >Votes</label
                                                    >
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="form-group"
                                            v-for="(
                                                plan, i
                                            ) in event.bulk_vote_plans"
                                            :key="i"
                                        >
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="plan.name"
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <input
                                                        type="number"
                                                        class="form-control"
                                                        v-model.number="
                                                            plan.amount
                                                        "
                                                        placeholder="50"
                                                        min="0"
                                                        step="any"
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <input
                                                        type="number"
                                                        class="form-control"
                                                        v-model.number="
                                                            plan.votes
                                                        "
                                                        placeholder="100"
                                                        min="0"
                                                        step="any"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="">Description</label>
                                <textarea-autosize
                                    class="form-control"
                                    v-model="event.description"
                                />
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="bank"
                                            >Settlement Bank</label
                                        >
                                        <select
                                            id="bank"
                                            class="form-control"
                                            required
                                            v-model="
                                                event.paystack.settlement_bank
                                            "
                                        >
                                            <option
                                                :value="bank.code"
                                                v-for="(bank, b) in banks"
                                                :key="b"
                                            >
                                                {{ bank.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="pricing"
                                            >Account Number</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            required
                                            v-model="
                                                event.paystack.account_number
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="pricing">Organizer's Percentage Charge</label>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    required
                                                    v-model.number=" event.paystack.percentage_charge "
                                                    :readonly="!isChangePercent"
                                                />
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <button v-if="!isChangePercent" type="button" class="btn btn-warning btn-block" @click="onSavePercentage">Switch</button>
                                                <button v-else type="button" class="btn btn-success btn-block" @click="onSavePercentage" >Save</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button
                                class="btn btn-success"
                                :disabled="isProcessing"
                            >
                                <i class="fa fa-save"></i>
                                Update Event
                                <Spinner v-if="isProcessing" />
                            </button>

                            <!-- <pre>{{ event }}</pre> -->

                            

                        </form>
                    </div>
                    <div class="col-md-4">
                        <label for="">Poster</label>

                        <div class="event-photo-wrapper" v-if="!isChangePhoto">
                            <img
                                :src="event.image || placeholder"
                                alt="event-image"
                                class="event-image"
                            />
                            <div>
                                <button
                                    class="btn btn-primary btn-block"
                                    @click="onChangePhoto"
                                >
                                    <i class="zmdi zmdi-image"></i>
                                    Change Photo
                                </button>
                            </div>
                        </div>

                        <div class="event-photo-wrapper" v-if="isChangePhoto">
                            <croppa
                                v-model="myCroppa"
                                ref="myCroppa"
                                :width="360"
                                :height="240"
                                :quality="2"
                                :accept="'image/*'"
                                :prevent-white-space="true"
                                @file-choose="onChooseFile"
                                @image-remove="onRemoveImage"
                                :placeholder="`Choose a photo. Minimum size: 720px x 480px`"
                                :placeholder-font-size="16"
                            />
                            <div>
                                <button
                                    class="btn btn-secondary btn-block"
                                    @click="onSelectFile"
                                >
                                    <i class="zmdi zmdi-image"></i>
                                    Choose Photo
                                </button>
                                <button
                                    class="btn btn-success btn-block"
                                    :disabled="!isChooseFile || isUploading"
                                    @click="savePhoto"
                                >
                                    <i class="zmdi zmdi-upload"></i>
                                    Save Photo
                                    <Spinner v-if="isUploading" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import dayjs from "dayjs";
import {
    collection,
    getDocs,
    getFirestore,
    getDoc,
    doc,
    setDoc,
    addDoc,
} from "@firebase/firestore";
import {
    getDownloadURL,
    getStorage,
    ref,
    uploadString,
} from "@firebase/storage";
import { nanoid } from "nanoid";
import { PaystackGhanaBanks } from "../../services/banks";
const db = getFirestore();
export default {
    data() {
        return {
            minDate: dayjs().toISOString(),
            datetime: "",
            myCroppa: {},
            event: {
                title: "",
                description: "",
                date: "",
                time: "",
                location: "",
                image: "",
                organizer: {},
                type: "",
                price: "",
                enable_bulk_votes: false,
                live_votes: true,
                bulk_vote_plans: [
                    {
                        name: "Silver",
                        amount: "",
                        votes: "",
                    },
                    {
                        name: "Bronze",
                        amount: "",
                        votes: "",
                    },
                    {
                        name: "Gold",
                        amount: "",
                        votes: "",
                    },
                ],
                paystack: {
                    settlement_bank: "",
                    account_number: "",
                    percentage_charge: "",
                },
                created_at: "",
                updated_at: "",
                go_live: false,
            },
            organizers: [],
            organizer: null,
            eventTypes: ["CONTEST", "FUNDRAISER"],
            isProcessing: false,
            placeholder: "/img/placeholder.jpg",

            isChangePhoto: false,
            isUploading: false,
            isChooseFile: false,
            banks: [],
            isChangePercent: false,
        };
    },
    created() {
        this.banks = PaystackGhanaBanks;
        this.getEvent();
        this.getOrganizers();
    },
    methods: {
        async getEvent() {
            this.isLoading = true;
            const eventId = this.$route.params.eventId;
            const ref = doc(db, `events/${eventId}`);
            const snapshot = await getDoc(ref);
            const data = snapshot.data();
            data.expiry = dayjs(data.date.toDate()).format("YYYY-MM-DD");
            data.company = data.organizer.name;
            if (typeof data.paystack === "undefined") {
                data.paystack = {
                    settlement_bank: "",
                    account_number: "",
                    percentage_charge: "",
                };
            }
            this.organizer = data.organizer;
            this.datetime = dayjs(data.date.toDate()).toISOString();
            this.event = data;
            this.isLoading = false;
        },

        async getOrganizers() {
            const col = collection(db, "organizers");
            const snapshot = await getDocs(col);
            const organizers = snapshot.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
            });
            this.organizers = organizers;
        },

        async updateEvent() {
            const eventId = this.$route.params.eventId;

            this.isProcessing = true;

            const { title, organizer, type, amount } = this.event;

            if (title.length == 0) {
                return this.$toast.error("Please enter a title");
            }

            if (organizer.id.length == 0) {
                return this.$toast.error("Please select an organizer");
            }

            if (type.length == 0) {
                return this.$toast.error("Please select an event type");
            }

            if (type == "CONTEST") {
                if (amount == "") {
                    return this.$toast.error("Please enter an amount");
                }
            }

            this.event.date = dayjs(this.datetime).toDate();

            this.event.updated_at = dayjs().toDate();

            const eventRef = doc(db, "events", eventId);

            await setDoc(eventRef, this.event, { merge: true });

            this.isProcessing = false;

            this.$toast.success("Event updated successfully");
        },

        async savePhoto() {
            this.isUploading = true;

            const base64 = this.myCroppa.generateDataUrl();

            if (base64.length == 0) {
                this.isUploading = false;
                return this.$toast.error("Please upload an image");
            }

            const uniqueId = nanoid();

            const extension = ".jpg";

            const filename = `events/${uniqueId}${extension}`;

            const metadata = { contentType: "image/jpeg" };

            const storage = getStorage();

            const storageRef = ref(storage, filename);

            const snapshot = await uploadString(
                storageRef,
                base64,
                "data_url",
                metadata
            );

            const url = await getDownloadURL(snapshot.ref);

            const eventId = this.$route.params.eventId;

            const eventRef = doc(db, "events", eventId);

            await setDoc(eventRef, { image: url }, { merge: true });

            this.event.image = url;

            this.$toast.success("Photo saved successfully");

            this.isUploading = false;
        },

        onSelectOrganizer(item) {
            const { name, id, logo } = item;
            this.event.organizer = { id, name, logo };
        },
        onRemoveOrganizer() {
            this.event.organizer = {};
        },
        onTypeChange() {
            if (this.event.type == "CONTEST") {
                this.event.enable_bulk_votes = false;
            }
        },
        onChangePhoto() {
            this.isChangePhoto = true;
        },
        onSelectFile() {
            this.$refs.myCroppa.chooseFile();
        },
        onChooseFile() {
            this.isChooseFile = true;
        },
        onRemoveImage() {
            this.isChooseFile = false;
        },

        onEditPercent(){
            this.isChangePercent = true;
        },
        async onSavePercentage(){

            this.$swal({
                title: "SWITCH PERCENTAGE",
                text: "You won't be able to switch the percentages?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete.isConfirmed) {
                    this.$toast.success("Percentage saved successfully");
                    const { eventId } = this.$route.params;
                    const percentage = this.event.paystack.percentage_charge;
                    const type = this.event.type.toLowerCase();
                    const old_percentage = percentage
                    const new_percentage = 100 - percentage
                    const created_at = dayjs().toDate()

                    this.event.paystack.percentage_charge = new_percentage;

                    const payload = { 
                        eventId, 
                        new_percentage,
                        old_percentage,
                        created_at,
                        type,
                    };
                    const percent_ref = collection(db, "percentage_changes");
                    await addDoc(percent_ref, payload);
                    this.isChangePercent = false;
                }
            });
        }



    },
};
</script>