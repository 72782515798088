<template>
    <section class="content">
        <header class="content__title">
            <h1>Transactions</h1>
            <small>A list of all transactions from all events</small>
        </header>

        <Placeholder v-if="isLoading" />

        <div v-else-if="transactions.length > 0">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Vote Type</th>
                                <th>Votes</th>
                                <th>Nominee</th>
                                <th>Status</th>
                                <th>Gateway</th>
                                <th>Channel</th>
                                <th>Nominee ID</th>
                                <th>Reference</th>
                                <th>Event</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in transactions" :key="i" v-tooltip="item.event_title">
                                <td>{{ item.created }}</td>
                                <td>{{ item.name }}</td>
                                <td>GHS {{ item.amount }}</td>
                                <td>{{ item.vote_type || 'N/A' }}</td>
                                <td>{{ item.votes || 'N/A' }}</td>
                                <td>{{ item.nominee_name }}</td>
                                <td>
                                    <span v-if="['SUCCESS','SUCCESSFUL','PAID'].includes(item.status)" class="badge badge-success">{{ item.status }}</span>
                                    <span v-else-if="['PENDING'].includes(item.status)" class="badge badge-warning">{{ item.status }}</span>
                                    <span v-else class="badge badge-danger">{{ item.status }}</span>
                                </td>
                                <td>{{ item.gateway || 'N/A' }}</td>
                                <td>{{ item.channel || 'N/A' }}</td>
                                <td>{{ item.nomineeId }}</td>
                                <td>{{ item.reference }}</td>
                                <td>{{ item.event_id }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <center class="mt-5 mb-5">
                <button class="btn btn-success btn-lg" @click="loadMore">
                    Load More
                    <Spinner v-if="isButtonLoading" />
                </button>
            </center>
        </div>

        <NotFound v-else />

    </section>
</template>

<script>
import { collection, getDocs, getFirestore, limit, orderBy, query, startAfter } from '@firebase/firestore';
import dayjs from 'dayjs';

const db = getFirestore();

export default {
    data(){
        return {
            isLoading: false,
            isButtonLoading: false,
            lastVisible: null,
            transactions: [],
            limit: 100,
        }
    },
    created(){
        this.onLoadTransactions();
    },
    methods: {

        formatBody(doc){
            const data = doc.data();
            data.id = doc.id;
            data.created = dayjs(data.created_at.toDate()).format('YYYY-MM-DD HH:mm:ss');
            if ('nominee' in data) {
                const { name } = data.nominee;
                data.nominee_name = name;
            } else {
                data.nominee_name = 'N/A';
            }

            ('event_title' in data) ? data.event_title : 'N/A';

            return data;
        },

        async onLoadTransactions(){
            this.isLoading = true;
            const transactionsRef = collection(db, 'transactions');
            const o = orderBy('created_at', 'desc');
            const l = limit(this.limit);
            const q = query(transactionsRef, o, l);
            const snapshot = await getDocs(q);
            this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
            this.transactions = snapshot.docs.map(doc => this.formatBody(doc));
            this.isLoading = false;
        },

        async loadMore(){
            this.isButtonLoading = true;
            const transactionsRef = collection(db, 'transactions');
            const o = orderBy('created_at', 'desc');
            const l = limit(this.limit);
            const s = startAfter(this.lastVisible);
            const q = query(transactionsRef, o, l, s);
            const snapshot = await getDocs(q);
            this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
            const transactions = snapshot.docs.map(doc => this.formatBody(doc));
            this.transactions = this.transactions.concat(transactions);
            this.isButtonLoading = false;
        },

    },
}
</script>


<style lang="scss" scoped>
th, td {
    white-space:nowrap;
}
</style>