<template>
    <section class="content">
        <header class="content__title">
            <h1>Add Event</h1>
            <small>Provide information about the event</small>
            <div class="actions"></div>
        </header>

        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <form @submit.prevent="saveEvent">

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="pricing">Event Type</label>
                                        <select class="form-control" id="pricing" v-model="event.type"
                                            @change="onTypeChange">
                                            <option v-for="(type, i) in eventTypes" :key="i">{{ type }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label for="">Event Title</label>
                                        <input type="text" class="form-control" v-model="event.title" required>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="">Description</label>
                                <textarea-autosize class="form-control" v-model="event.description" />
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Organizer</label>
                                        <Multiselect v-model="organizer" :options="organizers" label="name"
                                            track-by="id" placeholder="Select an organizer" @select="onSelectOrganizer"
                                            @remove="onRemoveOrganizer" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="date">Expiry Date</label>
                                        <Datetime v-model="datetime" input-id="date" input-class="form-control"
                                            type="datetime" :min-datetime="minDate" format="dd-MM-yyyy hh:mm a" auto />
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="['CONTEST', 'REGISTRATION'].includes(event.type)">
                                    <div class="form-group">
                                        <label for="pricing">Unit Price</label>
                                        <input type="number" class="form-control" placeholder="0.50" min="0" step="any"
                                            required v-model.number="event.price">
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="event.type == 'CONTEST'">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="pricing">Unique Prefix</label>
                                        <input type="text" class="form-control" placeholder="EG: GTA" required
                                            v-model="event.unique_id" v-uppercase @keyup="checkUniqueIdAvaliability">
                                        <span v-if="event.unique_id != '' && isAvailable == false">Unique ID already in
                                            used for another event</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="checkbox">
                                            <input type="checkbox" id="bulk-live-votes" v-model="event.live_votes">
                                            <label class="checkbox__label" for="bulk-live-votes">Display Votes</label>
                                        </div>
                                        <div class="checkbox">
                                            <input type="checkbox" id="bulk-votes" v-model="event.enable_bulk_votes">
                                            <label class="checkbox__label" for="bulk-votes">Enable Bulk Votes</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" v-if="event.enable_bulk_votes">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label for="pricing">Plan</label>
                                            </div>
                                            <div class="col-md-4">
                                                <label for="pricing">Amount</label>
                                            </div>
                                            <div class="col-md-4">
                                                <label for="pricing">Votes</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group" v-for="(plan, i) in event.bulk_vote_plans" :key="i">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" v-model="plan.name" readonly>
                                            </div>
                                            <div class="col-md-4">
                                                <input type="number" class="form-control input"
                                                    v-model.number="plan.amount" placeholder="" min="0" step="any">
                                            </div>
                                            <div class="col-md-4">
                                                <input type="number" class="form-control input"
                                                    v-model.number="plan.votes" placeholder="" min="0" step="any">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="bank">Settlement Bank</label>
                                        <select id="bank" class="form-control" required
                                            v-model="event.paystack.settlement_bank">
                                            <option :value="bank.code" v-for="(bank, b) in banks" :key="b">{{ bank.name
                                                }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="pricing">Account Number</label>
                                        <input type="text" class="form-control" required
                                            v-model="event.paystack.account_number">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="pricing">Organizer's Percentage</label>
                                        <input type="text" class="form-control" required
                                            v-model.number="event.paystack.percentage_charge">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="pricing">SmartKast's Percentage</label>
                                        <input type="text" class="form-control" required v-model.number="ourPercentage">
                                    </div>
                                </div>
                            </div>

                            <button class="btn btn-success" :disabled="isProcessing">
                                <i class="fa fa-save"></i>
                                Save Event
                                <Spinner v-if="isProcessing" />
                            </button>

                        </form>



                    </div>
                    <div class="col-md-4">
                        <label for="">Poster</label>
                        <div>
                            <croppa v-model="myCroppa" :width="360" :height="240" :quality="2" :accept="'image/*'"
                                :prevent-white-space="true" :placeholder="`Choose a photo. Minimum size: 720px x 480px`"
                                :placeholder-font-size="16" />
                        </div>
                    </div>
                </div>
                <!-- <pre>{{ event }}</pre> -->
            </div>
        </div>

    </section>
</template>

<script>
import dayjs from 'dayjs';
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, setDoc } from '@firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadString } from '@firebase/storage';
import { nanoid } from 'nanoid';
import { PaystackGhanaBanks } from '../../services/banks';
const db = getFirestore();
export default {
    data() {
        return {
            minDate: dayjs().toISOString(),
            datetime: "",
            myCroppa: {},
            event: {
                title: "",
                description: "",
                date: "",
                time: "",
                location: "",
                image: "",
                type: "",
                price: "",
                unique_id: "",
                enable_bulk_votes: false,
                live_votes: false,
                bulk_vote_plans: [
                    {
                        name: "Silver",
                        amount: "",
                        votes: "",
                    },
                    {
                        name: "Bronze",
                        amount: "",
                        votes: "",
                    },
                    {
                        name: "Gold",
                        amount: "",
                        votes: "",
                    },
                ],
                created_at: "",
                updated_at: "",
                go_live: false,
                organizer: {
                    id: "",
                    name: "",
                    logo: "",
                },
                paystack: {
                    settlement_bank: "",
                    account_number: "",
                    percentage_charge: "",
                }
            },
            organizers: [],
            organizer: null,
            eventTypes: ['CONTEST', 'FUNDRAISER', 'REGISTRATION'],
            isProcessing: false,
            banks: [],
            isAvailable: false,
        }
    },
    created() {
        this.banks = PaystackGhanaBanks;
        this.getOrganizers();
    },
    methods: {
        add() {
            this.$router.push('/events/add').catch(() => { });
        },
        async getOrganizers() {
            const col = collection(db, 'organizers');
            const snapshot = await getDocs(col);
            const organizers = snapshot.docs.map(doc => {
                return { ...doc.data(), id: doc.id }
            });
            organizers.unshift({ id: '', name: 'Create Organizer', logo: '' });
            this.organizers = organizers;
        },
        onSelectOrganizer(item) {
            const { name, id, logo = '', account_number, settlement_bank } = item;
            if (name == 'Create Organizer') {
                this.$router.push('/organizers/add?redirect=true').catch(() => { });
            } else {
                this.event.organizer = { id, name, logo };
                this.event.paystack.account_number = account_number;
                this.event.paystack.settlement_bank = settlement_bank;
            }
        },
        onRemoveOrganizer() {
            this.event.organizer = {};
        },
        onTypeChange() {
            this.event.enable_bulk_votes = false;
            this.event.live_votes = false;
        },
        async saveEvent() {

            try {

                this.isProcessing = true;

                const { title, organizer, type, amount } = this.event;

                if (type == 'CONTEST') {
                    if (this.isAvailable === false) {
                        this.$toast.error('Sorry! The unique ID cannot be used as it already exists.');
                        return;
                    }
                    const unique_id = this.event.unique_id.toUpperCase();
                    this.event.unique_id = unique_id;
                }
                if (['CONTEST', 'REGISTRATION'].includes(type)) {
                    if (amount == "") {
                        this.isProcessing = false;
                        this.$toast.error('Please enter an amount');
                        return
                    }
                }

                this.event.created_at = dayjs().toDate();
                this.event.updated_at = dayjs().toDate();

                const base64 = this.myCroppa.generateDataUrl();

                if (base64.length == 0) {
                    this.$toast.error('Please upload an image');
                    return
                }

                const extension = '.jpg';

                const filename = `events/${nanoid()}${extension}`;

                const metadata = { contentType: 'image/jpeg' };

                const storage = getStorage();

                const storageRef = ref(storage, filename);

                const snapshot = await uploadString(storageRef, base64, 'data_url', metadata);

                const url = await getDownloadURL(snapshot.ref);

                this.event.image = url;

                if (title.length == 0) {
                    this.isProcessing = false;
                    return this.$toast.error('Please enter a title');
                }

                if (organizer.id.length == 0) {
                    this.isProcessing = false;
                    return this.$toast.error('Please select an organizer');
                }

                if (type.length == 0) {
                    this.isProcessing = false;
                    return this.$toast.error('Please select an event type');
                }


                this.event.date = dayjs(this.datetime).toDate();

                const eventRef = collection(db, 'events');

                const { id: event_id } = await addDoc(eventRef, this.event);

                if (type == 'CONTEST') {
                    const { unique_id } = this.event;
                    const idRef = doc(db, 'event_unique_ids', unique_id);
                    await setDoc(idRef, {
                        unique_id,
                        event_id,
                        nominees: 0,
                    });
                }

                this.$toast.success('Event added successfully');

                if (type === 'CONTEST') {
                    this.$router.push(`/events/e/${event_id}/categories?title=${this.event.title}`).catch(() => { });
                } else {
                    this.$router.push(`/events/e/${event_id}`).catch(() => { });
                }

                this.onClear();

            } catch (error) {
                this.$toast.error(error.message);
                console.log(error);
            } finally {
                this.isProcessing = false;
            }


        },
        async checkUniqueIdAvaliability() {
            const unique_id = this.event.unique_id.toUpperCase();
            const count = unique_id.length;
            if (count >= 3) {
                const ref = doc(db, 'event_unique_ids', unique_id);
                const snapshot = await getDoc(ref);
                this.isAvailable = !snapshot.exists();
            } else if (count < 3) {
                this.isAvailable = false;
            }
        },
        onClear() {
            this.myCroppa.refresh();
            this.datetime = "";
            this.event = {
                title: "",
                description: "",
                date: "",
                time: "",
                location: "",
                image: "",
                type: "",
                price: "",
                enable_bulk_votes: false,
                bulk_vote_plans: [
                    {
                        name: "Silver",
                        amount: "",
                        votes: "",
                    },
                    {
                        name: "Bronze",
                        amount: "",
                        votes: "",
                    },
                    {
                        name: "Gold",
                        amount: "",
                        votes: "",
                    },
                ],
                created_at: "",
                updated_at: "",
                go_live: false,
                organizer: {
                    id: "",
                    name: "",
                    logo: "",
                },
                paystack: {
                    settlement_bank: "",
                    account_number: "",
                    percentage_charge: "",
                }
            }
        }
    },
    computed: {
        showBulkVotes() {
            const type = this.event.type;
            return type == 'CONTEST';
        },
        ourPercentage() {
            const { percentage_charge } = this.event.paystack;
            return (100 - percentage_charge) || 0;
        },
    }
}
</script>