<template>
    <section class="content">

        <header class="content__title">
            <h1>Winners</h1>
            <small>A list of all winners</small>
            <div class="actions"></div>
        </header>

        <ToolbarEvents />

        <!-- <pre>{{ contestants }}</pre> -->

        <div class="row">
            <div class="col-md-12">
                <Placeholder v-if="isLoading" />
                <div class="card" v-else-if="contestants.length > 0">
                    <div class="card-body">
                        <h4 class="card-title">Contentants</h4>
                        <h6 class="card-subtitle">List of all contestants in this category</h6>
                    </div>
                    <div class="listview">
                        <div class="listview__item" v-for="(item, i) in contestants" :key="i">
                            <progressive-img :src="item.photo" :blur="0" :placeholder="placeholder" :fallback="placeholder"
                                class="listview__img" />
                            <div class="listview__content">
                                <div class="listview__heading">{{ item.name }}</div>
                                <p>{{ item.nominee_code }}</p>
                                <p>{{ item.category }}</p>
                                <strong>{{ item.id }}</strong>
                            </div>
                            
                            <div class="actions">
                                <div>Votes: {{ item.votes || 0 }}</div>
                                <!-- <button class="btn btn-secondary btn-sm">View</button> -->
                            </div>

                        </div>
                    </div>
                    <div class="clearfix mb-4"></div>
                </div>
                <NotFound message="No contentants found" v-else />
            </div>
        </div>
    </section>
</template>

<script>

import { collection, doc, getDocs, getFirestore, orderBy, query, } from '@firebase/firestore';
const db = getFirestore();
export default {
    data() {
        return {
            contestants: [],
            nominees: [],
            nominee: {
                name: "",
                photo: "",
                eventId: "",
                categoryId: "",
                votes: 0,
            },
            isProcessing: false,
            myCroppa: {},
            myCroppaChange: {},
            isLoading: false,
            placeholder: '/img/placeholder-square.jpg',
            unsubscribe: null,
            event_title: "",
            event_type: "",
            event_price: "",
            category: "",
            unique_id: "",
            live_votes: false,
            event: {},
            isUploading: false,
            isChangePhoto: false,
            isChangingPhoto: false,
            selectedNomimee: {},
            isEditNominee: false,
        }
    },
    mounted() {
        this.loadContestants();
    },
    methods: {
        async loadContestants() {
            try {
                this.isLoading = true;
                const { eventId } = this.$route.params;
                const eventRef = doc(db, "events", eventId);

                // load categories
                const catRef = collection(eventRef, 'categories');
                const catDocs = await getDocs(catRef);

                let categories = catDocs.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                });

                // return only categories that have name key
                categories = categories.filter(item => ('name' in item));

                const docRef = collection(db, "events", eventId, 'nominees');
                const q = query(docRef, orderBy('votes', 'desc'));
                const snapshot = await getDocs(q);
                const contestants = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const { categoryId } = data;
                    data.id = doc.id;
                    const _category = categories.find(cat => cat.id === categoryId);
                    if (_category) {
                        const { name } = _category;
                        data.category = name;
                    } else {
                        data.category = null;
                    }
                    return data;
                });
                this.contestants = contestants;
                // this.unsubscribe = onSnapshot(q, snapshot => {
                // });
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }

        }
    },
    beforeDestroy() {
        if (this.unsubscribe != null) {
            this.unsubscribe();
        }
    }
}
</script>