import { getFirestore, getDocs, getDoc, doc, collection, orderBy, query } from '@firebase/firestore';
import dayjs from 'dayjs';

const firestore = getFirestore();

const artistesRef = collection(firestore, 'entries_artistes');

const albumsRef = collection(firestore, 'entries_albums');

const musicVideosRef = collection(firestore, 'entries_music_videos');

const totalArtistes = async () => {
    try {
        const snapshot = await getDocs(artistesRef);
        return {
            status: true,
            message: 'Artistes loaded',
            data: snapshot.size,
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }   
}

const totalAlbums = async () => {
    try {
        const snapshot = await getDocs(albumsRef);
        return {
            status: true,
            message: 'Albums loaded',
            data: snapshot.size,
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
}

const totalMusicVideos = async () => {
    try {
        const snapshot = await getDocs(musicVideosRef);
        return {
            status: true,
            message: 'Music videos loaded',
            data: snapshot.size,
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
}

const loadArtistes = async () => {
    try {
        const o = orderBy('created_at', 'desc');
        const q = query(artistesRef, o);
        const snapshot = await getDocs(q);
        const data = snapshot.docs.map(doc => {
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        const total = snapshot.size;
        return {
            status: true,
            message: 'Artistes loaded',
            data,
            total,
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
}

const loadAlbums = async () => {
    try {
        const o = orderBy('created_at', 'desc');
        const q = query(albumsRef, o);
        const snapshot = await getDocs(q);
        const data = snapshot.docs.map(doc => {
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        const total = snapshot.size;
        return {
            status: true,
            message: 'Albums loaded',
            data,
            total,
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
}

const loadMusicVideos = async () => {
    try {
        const o = orderBy('created_at', 'desc');
        const q = query(musicVideosRef, o);
        const snapshot = await getDocs(q);
        const data = snapshot.docs.map(doc => {
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        const total = snapshot.size;
        return {
            status: true,
            message: 'Music videos loaded',
            data,
            total,
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
}

const getArtiste = async (id) => {
    try {
        const artisteRef = doc(firestore, 'entries_artistes', id);
        const snapshot = await getDoc(artisteRef);
        if (snapshot.exists()) {
            const data = snapshot.data();
            const { created_at } = data;
            data.created = dayjs(created_at.toDate()).format('DD/MM/YYYY HH:mm:ss A');
            return {
                status: true,
                message: 'Artiste loaded',
                data,
            }
        } else {
            return {
                status: false,
                message: 'Artiste not found',
                data: null,
            }    
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
}

const getAlbum = async (id) => {
    try {
        const albumRef = doc(firestore, 'entries_albums', id);
        const snapshot = await getDoc(albumRef);
        if (snapshot.exists()) {
            const data = snapshot.data();
            const { created_at } = data;
            data.created = dayjs(created_at.toDate()).format('DD/MM/YYYY HH:mm:ss A');
            return {
                status: true,
                message: 'Album loaded',
                data,
            }
        } else {
            return {
                status: false,
                message: 'Album not found',
                data: null,
            }
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
}

const getMusicVideo = async (id) => {
    try {
        const musicVideoRef = doc(firestore, 'entries_music_videos', id);
        const snapshot = await getDoc(musicVideoRef);
        if (snapshot.exists()) {
            const data = snapshot.data();
            const { created_at } = data;
            data.created = dayjs(created_at.toDate()).format('DD/MM/YYYY HH:mm:ss A');
            return {
                status: true,
                message: 'Music video loaded',
                data,
            }
        } else {
            return {
                status: false,
                message: 'Music video not found',
                data: null,
            }
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
}

export {
    totalArtistes,
    totalAlbums,
    totalMusicVideos,
    loadArtistes,
    loadAlbums,
    loadMusicVideos,
    getArtiste,
    getAlbum,
    getMusicVideo,
}