<template>
    <section class="content">
        <header class="content__title">
            <h1>Organizers</h1>
            <small>A list of all event organizers</small>

            <div class="actions">
                <button class="btn btn-primary" @click="add">
                    <i class="zmdi zmdi-plus"></i> 
                    Add Organizer
                </button>
            </div>
        </header>

        <Placeholder v-if="isLoading" />

        <div class="contacts row" v-else-if="organizers.length > 0">
            <div class="col-xl-3 col-lg-3 col-sm-4 col-6" v-for="(item, i) in organizers" :key="i">
                <div class="contacts__item">
                    <a class="contacts__img">
                        <progressive-img 
                            :src="item.logo"
                            :blur="0"
                            :placeholder="placeholder"
                            :fallback="placeholder"
                            class="contacts__photo" 
                            />
                    </a>
                    <div class="contacts__info">
                        <strong>{{ item.name }}</strong>
                        <small>{{ item.email }}</small>
                        <small>{{ item.telephone || 'N/A' }}</small>
                        <h6 class="mt-3">{{ item.password || 'N/A' }}</h6>
                    </div>
                    <button class="btn btn-default" @click="onEdit(item.id)">Edit</button>
                    <button class="contacts__btn" @click="onDelete(item, i)">Delete</button>
                </div>
            </div>
        </div>

        <NotFound message="No organizers found" v-else />

    </section>
</template>

<script>
import { collection, deleteDoc, doc, getDocs, getFirestore } from '@firebase/firestore';
const firestore = getFirestore();
export default {
    data(){
        return {
            organizers: [],
            isLoading: false,
            placeholder: '/img/placeholder-square.jpg',
        }
    },
    created(){
        this.load();
    },
    methods: {
        add(){
            this.$router.push('/organizers/add').catch(() => {});
        },
        onEdit(id){
            this.$router.push('/organizers/o/' + id + '/edit').catch(() => {});
        },
        onDelete(item, i){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this record!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass: 'btn btn-danger',
                cancelButtonClass: 'btn btn-success',
            }).then(async value => {
                if (value.isConfirmed) {

                    this.organizers.splice(i, 1);

                    this.$toast.success('Organizer deleted successfully');
                    
                    const { id, uid } = item;
                    
                    // delete from organizers
                    const orgRef =  doc(firestore, 'organizers', id);
                    await deleteDoc(orgRef);

                    // delete from auth
                    if (typeof uid !== 'undefined') {

                        // reference to the users document
                        const userRef = doc(firestore, 'users', uid);

                        // delete the user from users
                        await deleteDoc(userRef);

                    }

                }
            }).catch(() => {});
        },
        async load(){
            this.isLoading = true;
            const organizerRef = collection(firestore, 'organizers');
            const snapshot = await getDocs(organizerRef);
            this.organizers = snapshot.docs.map(doc => {
                return { id: doc.id, ...doc.data() }
            });
            this.isLoading = false;
        },
    }
}
</script>