<template>
    <aside class="sidebar" :class="$store.getters.getMobileMenu ? 'toggled' : ''" id="sidebar" >
        <div class="scrollbar-inner">
            <div class="user">
                <div class="user__info">
                    <progressive-img 
                        :src="user.photo"
                        :blur="0"
                        :placeholder="placeholder"
                        :fallback="placeholder"
                        class="user__img" 
                        />
                    <div>
                        <div class="user__name">{{ user.name }}</div>
                        <div class="user__email">{{ user.email }}</div>
                    </div>
                </div>
            </div>

            <ul class="navigation">
                
                <li>
                    <router-link to="/">
                        <i class="zmdi zmdi-home"></i>
                        Home
                    </router-link>
                </li>
                <li>
                    <router-link to="/events">
                        <i class="zmdi zmdi-widgets"></i>
                        Events
                    </router-link>
                </li>
                <li>
                    <router-link to="/requisitions">
                        <i class="zmdi zmdi-money"></i>
                        Requisitions
                    </router-link>
                </li>
                <li>
                    <router-link to="/organizers">
                        <i class="zmdi zmdi-city-alt"></i>
                        Organizers
                    </router-link>
                </li>
                <li>
                    <router-link to="/transactions">
                        <i class="zmdi zmdi-assignment"></i>
                        Transactions
                    </router-link>
                </li>
                <li>
                    <router-link to="/users">
                        <i class="zmdi zmdi-accounts"></i>
                        Users
                    </router-link>
                </li>
                <li>
                    <router-link to="/nominations">
                        <i class="zmdi zmdi-account"></i>
                        Nominations
                    </router-link>
                </li>
                <li>
                    <router-link to="/settings">
                        <i class="zmdi zmdi-settings"></i>
                        Settings
                    </router-link>
                </li>
                <li>
                    <router-link to="/registrations">
                        <i class="zmdi zmdi-edit"></i>
                        Registrations
                    </router-link>
                </li>
                
            </ul>
        </div>
    </aside>
</template>

<script>
export default {
    data() {
        return {
           user: this.$store.getters.getUser,
           placeholder: '/img/placeholder-square.jpg',
        }
    },
}
</script>