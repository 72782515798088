<template>
    <section class="content">
        <header class="content__title">
            <h1>Event Details</h1>
            <small>Event information</small>

            <div class="actions">
                <button class="btn btn-success" @click="back">
                    <i class="zmdi zmdi-plus"></i>
                    Events
                </button>
            </div>
        </header>

        <ToolbarEvents />

        <Placeholder v-if="isLoading" />

        <div class="row" v-else>
            <div class="col-md-3">
                <img :src="event.image" alt="" class="img-fluid">
                
            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">{{ event.title }}</h4>
                        <h6 class="card-subtitle">{{ event.expiry }}</h6>
                        <p>{{ event.description }}</p>
                        <p>Type: {{ event.type }}</p>
                        <p v-show="event.type == 'CONTEST'">Price: GHS {{ event.price }}</p>
                        <p>Organizer: {{ event.company }}</p>
                        <p>Bulk Votes Enabled: {{ event.enable_bulk_votes ? 'Yes' : 'No' }}</p>
                        <p>Go Live: {{ event.go_live ? 'Yes' : 'No' }}</p>
                        <div class="row mt-5">
                            <div class="col-md-6">
                                <div class="checkbox">
                                    <input type="checkbox" id="go-live" v-model="event.go_live" @change="onGoLiveChanged">
                                    <label class="checkbox__label" for="go-live">Check to go live</label>
                                </div>
                            </div>
                            <div class="col-md-6" v-show="event.type == 'CONTEST'">
                                <div class="checkbox">
                                    <input type="checkbox" id="live-votes" v-model="event.live_votes"
                                        @change="onLiveVoteChanged">
                                    <label class="checkbox__label" for="live-votes">Display Live Votes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" v-show="event.type == 'CONTEST'">
                    <div class="card-body">

                        <div class="checkbox">
                            <input type="checkbox" id="bulk-votes" v-model="event.enable_bulk_votes"
                                @change="onBulkVotesEnabled">
                            <label class="checkbox__label" for="bulk-votes">Enable Bulk Votes</label>
                        </div>

                        <div class="mt-5" v-if="event.enable_bulk_votes">
                            <form @submit.prevent="onBulkVoteSaved">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="pricing">Amount</label>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="pricing">Amount</label>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="pricing">Votes</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" v-for="(plan, i) in event.bulk_vote_plans" :key="i">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text" class="form-control" v-model="plan.name">
                                        </div>
                                        <div class="col-md-4">
                                            <input type="number" class="form-control" v-model.number="plan.amount"
                                                placeholder="50" min="0" step="any">
                                        </div>
                                        <div class="col-md-4">
                                            <input type="number" class="form-control" v-model.number="plan.votes"
                                                placeholder="100" min="0" step="any">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-success" :disabled="isUpdating">
                                        <i class="zmdi zmdi-save"></i>
                                        Update Bulk Votes
                                        <Spinner v-if="isUpdating" />
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                <div class="card" v-show="event.type == 'CONTEST'">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Categories</th>
                                <th class="text-right">
                                    <button class="btn btn-secondary btn-sm" type="button" @click="createCategory">
                                        <i class="zmdi zmdi-plus icon"></i>
                                        Add Category
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(category, c) in categories" :key="c">
                                <td>{{ category.name }}</td>
                                <td class="text-right">
                                    <button class="btn btn-success ml-2 btn-sm" type="button" @click="addNominee(category)">
                                        <i class="zmdi zmdi-plus icon"></i>
                                        Add Contestants
                                    </button>
                                    <button class="btn btn-danger ml-2 btn-sm" type="button">
                                        <i class="zmdi zmdi-delete icon"></i>
                                        Delete Category
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-show="event.type == 'CONTEST'">
                    <div v-for="(item, n) in loadContestantsByCategory" :key="n">
                        <div class="category-heading">
                            <div>{{ item.category_name }}</div>
                            <div>
                                <button class="btn btn-success btn-sm" type="button" @click="addNominee(item)">
                                    <i class="zmdi zmdi-plus icon"></i>
                                    Add Contestants
                                </button>
                            </div>
                        </div>
                        <div class="contacts row" v-if="item.category_contestants.length > 0">
                            <div class="col-xl-4 col-lg-3 col-sm-4 col-6" v-for="(nominee, m) in item.category_contestants"
                                :key="m">
                                <div class="contacts__item">
                                    <a class="contacts__img">
                                        <progressive-img :src="nominee.photo" :blur="0" :placeholder="placeholder"
                                            :fallback="placeholder" class="contacts__photo" />
                                    </a>
                                    <div class="contacts__info">
                                        <strong>{{ nominee.name }}</strong>
                                        <strong>{{ nominee.votes || 0 + ` votes` }}</strong>
                                        <strong>{{ nominee.isEvicted ? 'Evicted' : 'Active' }}</strong>
                                        <code>{{ nominee.nominee_code }}</code>
                                        <strong>{{ nominee.nomineeId }}</strong>
                                        <!-- <pre>{{ nominee }}</pre> -->
                                    </div>
                                    <button class="contacts__btn" @click="onEvictNominee(nominee)">
                                        {{ nominee.isEvicted ? 'Activate' : 'Evict' }}
                                    </button>

                                    <button class="contacts__btn bnt-warning" @click="onCreditVotes(nominee)">
                                        Credit Votes
                                    </button>

                                    <button class="contacts__btn bnt-warning" @click="onDeleteNominee(nominee)">
                                        Delete
                                    </button>

                                </div>
                            </div>
                        </div>
                        <NotFound message="No Contestants Found" v-else />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, runTransaction, deleteDoc } from '@firebase/firestore';
import dayjs from 'dayjs';
const db = getFirestore();
export default {
    data() {
        return {
            event: {},
            categories: [],
            nominees: [],
            isLoading: false,
            isUpdating: false,
            placeholder: '/img/placeholder-square.jpg',
            unsubscribe: null,
        }
    },
    created() {
        this.getEvent()
    },
    methods: {

        back() {
            this.$router.push('/events').catch(() => { });
        },

        addNominee(item) {
            const { category_id } = item;
            const eventId = this.$route.params.eventId;
            this.$router.push(`/events/e/${eventId}/c/${category_id}/nominees`).catch(() => { });
        },

        createCategory() {
            const eventId = this.$route.params.eventId;
            const { title } = this.event
            this.$router.push(`/events/e/${eventId}/categories?title=${title}`).catch(() => { });
        },

        onEvictNominee(nominee) {
            const { nomineeId, eventId, isEvicted } = nominee;
            this.$swal({
                title: isEvicted ? 'Activate Nominee' : 'Evict Nominee',
                text: `Are you sure you want to ${isEvicted ? 'activate' : 'evict'} this nominee?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: isEvicted ? 'Activate' : 'Evict',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isUpdating = true;
                    const ref = doc(db, `events`, eventId, 'nominees', nomineeId);
                    await setDoc(ref, { isEvicted: !isEvicted }, { merge: true });
                }
            });
        },

        async onCreditVotes(nominee) {
            const { nomineeId, eventId } = nominee;
            const { value: vote } = await this.$swal({
                title: 'CREDIT VOTES',
                icon: "warning",
                text: 'Are you sure you want to credit votes to this nominee?',
                input: 'number',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You enter a valid numeric value'
                    }
                }
            })
            if (vote) {
                try {
                    const nomineeRef = doc(db, `events`, eventId, 'nominees', nomineeId);
                    await runTransaction(db, async (transaction) => {
                        const nomineeDoc = await transaction.get(nomineeRef);
                        if (!nomineeDoc.exists) {
                            throw 'Nominee does not exist';
                        }
                        const { votes } = nomineeDoc.data();
                        const newTotal = parseInt(votes) + parseInt(vote);
                        transaction.set(nomineeRef, { votes: newTotal }, { merge: true });
                    });
                } catch (error) {
                    this.$toast.error(JSON.stringify(error));
                }
            }
        },

        async onDeleteNominee(nominee) {
            const { nomineeId, eventId } = nominee
            this.$swal({
                title: 'Delete Nominee',
                text: `Are you sure you want to delete this nominee?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isUpdating = true;
                    const ref = doc(db, `events`, eventId, 'nominees', nomineeId);
                    await deleteDoc(ref);
                }
            });
        },

        async getEvent() {
            this.isLoading = true;
            const eventId = this.$route.params.eventId;
            const ref = doc(db, `events/${eventId}`);
            const snapshot = await getDoc(ref);
            const data = snapshot.data();
            data.expiry = dayjs(data.date.toDate()).format('DD MMMM, YYYY');
            data.company = data.organizer.name;
            this.event = data;
            if (data.type == 'CONTEST') {
                this.onLoadCategories();
                this.onLoadNominees();
            }
            this.isLoading = false;
        },

        async onBulkVotesEnabled($event) {
            const eventId = this.$route.params.eventId;
            const { checked } = $event.target;
            const ref = doc(db, `events`, eventId);
            if (!checked) {
                this.$toast.success("Bulk voted is now disabled");
                await setDoc(ref, { enable_bulk_votes: false }, { merge: true });
            }
        },

        async onGoLiveChanged($event) {
            const eventId = this.$route.params.eventId;
            const { checked } = $event.target;
            const ref = doc(db, `events`, eventId);
            if (checked) {
                this.$toast.success("Event is now live");
                await setDoc(ref, { go_live: true }, { merge: true });
            } else {
                this.$toast.success("Event is now offline");
                await setDoc(ref, { go_live: false }, { merge: true });
            }
        },

        async onLiveVoteChanged($event) {
            const eventId = this.$route.params.eventId;
            const { checked } = $event.target;
            const ref = doc(db, `events`, eventId);
            if (checked) {
                this.$toast.success("Live votes is now enabled");
                await setDoc(ref, { live_votes: true }, { merge: true });
            } else {
                this.$toast.success("Live votes is now disabled");
                await setDoc(ref, { live_votes: false }, { merge: true });
            }
        },

        async onBulkVoteSaved() {
            this.isUpdating = true;
            const bulk_vote_plans = this.event.bulk_vote_plans;
            const eventId = this.$route.params.eventId;
            const ref = doc(db, `events`, eventId);
            const payload = { bulk_vote_plans, enable_bulk_votes: true };
            this.$toast.success("Bulk voted is saved");
            await setDoc(ref, payload, { merge: true });
            this.isUpdating = false;
        },

        async onLoadCategories() {
            const eventId = this.$route.params.eventId;
            const ref = collection(db, 'events', eventId, 'categories');
            const snapshot = await getDocs(ref);
            const categories = snapshot.docs.map(doc => {
                const data = doc.data();
                data.category_id = doc.id;
                return data;
            });
            this.categories = categories;
        },

        onLoadNominees() {
            const eventId = this.$route.params.eventId;
            const ref = collection(db, 'events', eventId, 'nominees');
            const q = query(ref, orderBy('votes', 'desc'));
            this.unsubscribe = onSnapshot(q, snapshot => {
                const nominees = snapshot.docs.map(doc => {
                    const data = doc.data();
                    data.nomineeId = doc.id;
                    return data;
                });
                this.nominees = nominees;
            });
        },

    },
    computed: {
        loadContestantsByCategory() {
            const nominees = this.nominees;
            const categories = this.categories;
            const data = [];
            for (const category of categories) {
                const { category_id, name } = category;
                const nominees_by_category = nominees.filter(nominee => {
                    return nominee.categoryId == category_id;
                });
                data.push({
                    category_id,
                    category_name: name,
                    category_contestants: nominees_by_category,
                })
            }
            return data;
        },
    },
    beforeDestroy() {
        if (this.unsubscribe != null) {
            this.unsubscribe();
        }
    },
}
</script>