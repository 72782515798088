<template>
    <section class="content">
        <header class="content__title">
            <h1>Events</h1>
            <small>Welcome to the unique Material Design admin web app experience!</small>
            <div class="actions"></div>
        </header>


        <ToolbarEvents />
        <Placeholder v-if="isLoading" />

        <div v-else-if="events.length > 0">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Votes</th>
                                <th>Nominee</th>
                                <th>Reference</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(event, i) in events" :key="i">
                                <td>{{ event.created }}</td>
                                <td>{{ event.name }}</td>
                                <td>GHS {{ event.amount }}</td>
                                <td>{{ event.votes || 'N/A' }}</td>
                                <td>{{ event.nominee_name }}</td>
                                <td>{{ event.reference }}</td>
                                <td>{{ event.status }}</td>
                                <td>
                                    <button 
                                        type="button" 
                                        class="btn btn-sm btn-success"
                                        :disabled="event.isRetrying" 
                                        @click="retryNalo(event, i)">
                                        <span v-if="event.isRetrying">Verifying...</span>
                                        <span v-else>{{ event.email == '' || event.is_completed == true || event.status != 'PENDING' ? 'Verified' : 'Verify'  }}</span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <NotFound v-else />

        <!-- <pre>{{ verifyData }}</pre> -->

    </section>
</template>

<script>
import { getFunctions, httpsCallable } from '@firebase/functions';
import { collection, getDocs, getFirestore, limit,  orderBy, query, startAfter, where, onSnapshot } from '@firebase/firestore';

import dayjs from 'dayjs';

const db = getFirestore();
const functions = getFunctions();


export default {
    data(){
        return {
            events: [],
            limit: 25,
            isLoading: false,
            isButtonLoading: false,
            isRecalculating: false,
            isReseting: false,
            lastVisible: null,
            total: 0,
            unsubscribe: null,
            isExporting: false,
            nomineeTotals: [],
            verifyData: {},
        }
    },
    created(){
        this.getTransactions();
    },
    methods: {
        formatBody(doc){
            const data = doc.data();
            data.id = doc.id;
            data.created = dayjs(data.created_at.toDate()).format('YYYY-MM-DD HH:mm:ss');
            data.isRetrying = false;
            if ('nominee' in data) {
                const { name } = data.nominee;
                data.nominee_name = name;
            } else {
                data.nominee_name = 'N/A';
            }
            return data;
        },

        getTransactions(){
            this.isLoading = true;
            const eventId = this.$route.params.eventId;
            const eventRef = collection(db, 'transactions');
            const q = query(
                eventRef, 
                where('event_id', '==', eventId),
                where('is_completed', '==', false),
                where('status', '==', 'PENDING'),
                orderBy('created_at', 'desc'),
                limit(this.limit)
            );
            onSnapshot(q, snapshot => {
                this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
                this.events = snapshot.docs.map(doc => this.formatBody(doc));
                this.isLoading = false;
            });
        },

        async loadMore(){
            this.isButtonLoading = true;
            const eventId = this.$route.params.eventId;
            const eventRef = collection(db, 'transactions');
            const q = query(
                eventRef, 
                where('event_id', '==', eventId),
                orderBy('created_at', 'desc'),
                limit(this.limit),
                startAfter(this.lastVisible)
            );
            const snapshot = await getDocs(q);
            this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
            const events = snapshot.docs.map(doc => this.formatBody(doc));
            this.events = this.events.concat(events);
            this.isButtonLoading = false;
        },
        
        async retryNalo(event, i){
            const { reference } = event;
            this.events[i].isRetrying = true;
            await httpsCallable(functions, 'verifyNaloSolutionsPayment')({ reference });
            // this.events[i].isRetrying = false;
        },

    },
    beforeDestroy(){
        if (this.unsubscribe != null) {
            this.unsubscribe();
        }
    }
}
</script>