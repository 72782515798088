<template>
    <section class="content">

        <header class="content__title">
            <h1>Add Categories</h1>
            <small>{{ $route.query.title }}</small>
            <div class="actions">
                <button class="btn btn-danger ml-3" type="button" :disabled="isUploading" @click="onSelectNomineesFile">
                    <i class="zmdi zmdi-upload mr-2"></i> 
                    Upload Category
                    <Spinner v-if="isUploading" />
                </button>
                <input
                    type="file" 
                    class="category-file" 
                    ref="file" 
                    accept=".xlsx,.xls,.csv"
                    @change="onCategorySelected">
            </div>
        </header>

        
        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="saveCategory" v-show="!isEdit">
                            <div class="form-group">
                                <label for="">Name of Category</label>
                                <input type="text" class="form-control" v-model="category" required>
                            </div>
                            <button class="btn btn-success" type="submit" :disabled="isProcessing">
                                <i class="zmdi zmdi-save mr-2"></i> 
                                Save Category
                                <Spinner v-if="isProcessing" />
                            </button>
                        </form>

                        <form @submit.prevent="updateCategory" v-show="isEdit">
                            <div class="form-group">
                                <label for="">Name of Category</label>
                                <input type="text" class="form-control" v-model="category" required>
                            </div>
                            <button class="btn btn-success" type="submit" :disabled="isProcessing">
                                <i class="zmdi zmdi-save mr-2"></i> 
                                Update Category
                                <Spinner v-if="isProcessing" />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <Placeholder v-if="isLoading" />
                <div class="card" v-else-if="categories.length > 0">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th class="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in categories" :key="i">
                                    <td>{{ item.name }}</td>
                                    <td class="text-right">
                                        <button class="btn btn-warning btn-sm icon ml-2" @click="editCategory(item)">
                                            <i class="zmdi zmdi-edit icon"></i>
                                            Edit
                                        </button>
                                        <button class="btn btn-danger btn-sm icon ml-2" @click="deleteCategory(item.id)">
                                            <i class="zmdi zmdi-delete icon"></i>
                                            Delete
                                        </button>
                                        <button class="btn btn-success btn-sm icon ml-2" @click="addNominees(item.id)">
                                            <i class="zmdi zmdi-plus icon"></i>
                                            Add Nominies
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <NotFound v-else />
            </div>
        </div>
    </section>
</template>

<script>

import { read, utils } from 'xlsx';
import dayjs from 'dayjs';
import { addDoc, collection, deleteDoc, doc, getFirestore, onSnapshot, orderBy, query, setDoc } from '@firebase/firestore';
const db = getFirestore();
export default {
    data(){
        return {
            category: "",
            categoryId: "",
            categories: [],
            isProcessing: false,
            isLoading: false,
            isUploading: false,
            isEdit: false,
            isUpdating: false,
            unsubscribe: null
        }
    },
    created(){
        this.loadCategories();
    },
    methods: {
        deleteCategory(categoryId){
            const eventId = this.$route.params.eventId;
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then( async (result) => {
                if (result.value) {
                    this.isProcessing = true;
                    const eventRef = doc(db, 'events', eventId, 'categories', categoryId);
                    await deleteDoc(eventRef);
                    this.isProcessing = false;
                }
            });
        },
        addNominees(id){
            const eventId = this.$route.params.eventId;
            const eventTitle = this.$route.query.title;
            this.$router.push(`/events/e/${eventId}/c/${id}/nominees?title=${eventTitle}`).catch(() => {});
        },
        editCategory(category){
            this.isEdit = true;
            this.category = category.name;
            this.categoryId = category.id;
        },
        onCategorySelected($event){
            const file = $event.target.files ? $event.target.files[0] : null;
            const eventId = this.$route.params.eventId;
            if (file) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    /* Parse data */
                    const bstr = e.target.result;
                    // read the workbook
                    const wb = read(bstr, { type: 'binary' });
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = utils.sheet_to_json(ws, { header: 1 });

                    // delete the first row
                    data.shift();

                    this.isUploading = true;
                    for (const item of data) {
                        const name = item[0];
                        const payload = { 
                            name,
                            eventId,
                            createdAt: dayjs().toDate(),
                        };
                        const eventRef = collection(db, 'events', eventId, 'categories');
                        await addDoc(eventRef, payload);
                    }
                    this.isUploading = false;
                    this.$toast.success("Categories uploaded successfully");
                }

                reader.readAsBinaryString(file);
            }

        },
        onSelectNomineesFile(){
            this.$refs.file.click();
        },
        async loadCategories(){
            const eventId = this.$route.params.eventId;
            const docRef = collection(db, "events", eventId, 'categories');
            const q = query(docRef, orderBy('createdAt', 'desc'));
            this.isLoading = true;
            this.unsubscribe = onSnapshot(q, snapshot => {
                this.categories = snapshot.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                })
                this.isLoading = false;
            });
            // const unsubscribe = () => {}
            
        },
        async saveCategory(){
            const { category } = this;
            if (category.length == 0) {
                return this.$toast.error('Please enter a category name');
            }
            this.isProcessing = true;
            const eventId = this.$route.params.eventId;
            const payload = { 
                name: category, 
                eventId,
                createdAt: dayjs().toDate(),
            };
            const eventRef = collection(db, 'events', eventId, 'categories');
            await addDoc(eventRef, payload);
            this.category = "";
            this.$toast.success('Category added successfully');
            this.isProcessing = false;
        },
        async updateCategory(){
            const { category: name, categoryId } = this;
            if (name.length == 0) return this.$toast.error('Please enter a category name');
            this.isProcessing = true;
            const eventId = this.$route.params.eventId;
            const payload = { name };
            const eventRef = doc(db, 'events', eventId, 'categories', categoryId);
            await setDoc(eventRef, payload, { merge: true });
            this.category = "";
            this.$toast.success('Category added successfully');
            this.isEdit = false;
            this.isProcessing = false;
        },
    },
    beforeDestroy(){
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }
}
</script>