<template>
    <form @submit.prevent="save">
        <div class="modal fade show" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ type.toUpperCase() }}</h5>
                        <button type="button" class="btn-close" @click="close">
                            <i class="zmdi zmdi-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Event</label>
                            <select class="form-control" v-model="item.event_id" @change="onChangeEvent" required>
                                <option :value="event.event_id" v-for="(event, i) in events" :key="i">
                                    {{ event.title }}
                                </option>
                            </select>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Expiry Date</label>
                                    <Datetime v-model="expiryDate" input-id="date" input-class="form-control"
                                        type="datetime" format="dd-MM-yyyy hh:mm a" auto :min-datetime="minDate"
                                        required />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Price</label>
                                    <input type="number" min="0" step="any" class="form-control" v-model="item.price"
                                        required>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check" v-for="(item, i) in item.input_parameters" :key="i">
                                <input class="form-check-input" type="checkbox" :id="`input-${item.label}`"
                                    :disabled="item.disabled" v-model="item.checked">
                                <label class="form-check-label" :for="`input-${item.label}`">{{ item.label }}</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="input-live" v-model="item.live">
                                <label class="form-check-label" for="input-live">Go Live</label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="close">Close</button>
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                    <!-- <div style="max-height: 400px; overflow: auto;">
                        <pre>{{ registration }}</pre>
                    </div> -->
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { addDoc, collection, getDocs, getFirestore, orderBy, query, where, doc, setDoc } from '@firebase/firestore';
import dayjs from 'dayjs';
const db = getFirestore();
export default {
    data() {
        return {
            registerData: {
                event: null,
                organizer: null,
                event_id: null,
                organizer_id: null,
                expiry_date: null,
                price: null,
                status: false,
                live: false,
                created_at: null,
                updated_at: null,
                input_parameters: [
                    {
                        label: 'Telephone',
                        checked: true,
                        disabled: true,
                    },
                    {
                        label: 'Name',
                        checked: false,
                        disabled: false,
                    },
                    {
                        label: 'Email',
                        checked: false,
                        disabled: false,
                    },
                ],
            },
            events: [],
            organizers: [],
            minDate: dayjs().toISOString(),
            expiryDate: null,
            isLoading: false,
            isProcessing: false,
            item: {},
        }
    },
    emits: ['close'],
    props: {
        type: {
            type: String,
            default: 'add',
        },
        registration: {
            type: Object,
            default: () => { },
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async loadEvents() {
            const e = dayjs().toDate();
            const r = collection(db, 'events');
            const w = where('date', '>', e);
            const o = orderBy('created_at', 'desc');
            const q = query(r, w, o);
            const snapshot = await getDocs(q);
            const events = snapshot.docs.map(doc => {
                const d = doc.data();
                d.event_id = doc.id;
                d.title = d.title.toUpperCase();
                return d;
            });
            this.events = events;
        },
        onChangeEvent($event) {
            const { value } = $event.target;
            const event = this.events.find(e => e.event_id == value);
            const { organizer } = event;
            const { id } = organizer;
            this.item.organizer_id = id;
            this.item.event = event.title;
            this.item.organizer = organizer.name;
        },
        async save() {
            const payload = this.item;
            payload.price = Number(payload.price);
            payload.created_at = dayjs().toDate();
            payload.updated_at = dayjs().toDate();
            payload.expiry_date = dayjs(this.expiryDate).toDate();

            const r = collection(db, 'registrations');
            if (this.type == 'add') {
                await addDoc(r, payload);
                this.$toast.success('Registration added successfully');
            } else {
                const ref = doc(r, this.registration.id);
                console.log(this.registration.id);
                await setDoc(ref, payload, { merge: true });
                this.$toast.success('Registration updated successfully');
            }
            this.close();
        },
    },
    mounted() {
        this.loadEvents();
        if (this.type == 'add') {
            this.item = this.registerData;
            this.item.event = null;
            this.item.organizer = null;
            this.expiryDate = null;
        } else {
            this.item = this.registration;
            this.item.event = this.registration.event ? this.registration.event : {};
            this.item.organizer = this.registration.organizer ? this.registration.organizer : {};
            const { expiry_date } = this.item;
            this.expiryDate = dayjs(expiry_date.toDate()).toISOString();
        }
    },

}
</script>