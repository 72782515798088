import { getFirestore, getDocs, collection, orderBy, query, addDoc, where, doc, increment, setDoc, getDoc, updateDoc } from '@firebase/firestore';

import dayjs from 'dayjs';

export const firestore = getFirestore();

export const eventsRef = collection(firestore, 'events');

export const recalculateRef = collection(firestore, 'recalculate_event_performance');

export const loadEvents = async () => {
    const o = orderBy('created_at', 'desc');
    const q = query(eventsRef, o);
    try {
        const snapshot = await getDocs(q);
        if (snapshot.empty) {
            return {
                status: false,
                message: 'No events found',
                data: []
            }
        } else {
            const data = snapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            });
            const total = snapshot.size;
            return {
                status: true,
                message: 'Events loaded',
                data,
                total,
            }
        }

    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }

}

export const addRecalculatePerformance = async (events) => {
    try {
        const { id } = await addDoc(recalculateRef, events);
        return {
            status: true,
            message: 'Recalculate events added',
            data: id,
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
}

export const verify_nalo_solutions_v2 = async () => {
    const transactionsRef = collection(firestore, 'transactions');
    const w = where('channel', '==', 'USSD');
    const q = query(transactionsRef, w);
    try {
        const snap = await getDocs(q);
        const transactions = snap.docs.map(doc => doc.id);
        return {
            status: true,
            message: 'NALOSOLUTIONS',
            data: transactions,
            total: snap.size,
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
            total: 0,
        }
    }
}

export const resetVotes = async (eventId) => {
    const eventRef = doc(firestore, 'events', eventId);
    const nomineesRef = collection(eventRef, 'nominees');

    const lastResetDate = dayjs().toDate();

    // // get the event
    // const snap_1 = await getDoc(eventRef);

    // const eventData = snap_1.data();

    // const { last_reset_date } = eventData;

    // if ("last_reset_date" in eventData) {
    //     // last_reset_date
    //     console.log(last_reset_date);
    // }

    try {
        const snapshot = await getDocs(nomineesRef);
        const nominees = snapshot.docs.map(doc => {
            const data = doc.data();
            const { votes } = data;
            return {
                id: doc.id,
                votes,
            }
        });

        for (const item of nominees) {
            const { id, votes } = item;
            const nomineeRef = doc(nomineesRef, id);
            const date = dayjs().format('YYYY-MM-DD-HH-mm-ss');
            const payload = {
                votes: 0,
                votes_previous: increment(votes),
                reset_dates: {
                    [date]: increment(votes),
                }
            }
            await setDoc(nomineeRef, payload, { merge: true });
        }

        // update the last reset date
        console.log("lastResetDate", lastResetDate);
        await setDoc(eventRef, { last_reset_date: lastResetDate }, { merge: true });
        console.log("lastResetDate", lastResetDate);

        return {
            status: true,
            message: 'Votes reset',
            data: null,
        }
    } catch (error) {
        return {
            status: false,
            message: error.message,
            data: null,
        }
    }
};

export const recalculateTransactions = async (eventId) => {
    let response = {};




    try {


        // const ls = dayjs('2023-10-05').hour(22).minute(0).second(0).toDate();

        // lastResetDate = ls;

        let lastResetDate = null, q = null;
        const eventRef = doc(firestore, 'events', eventId);
        const eventSnap = await getDoc(eventRef);
        const eventData = eventSnap.data();

        if ("last_reset_date" in eventData) {
            const { last_reset_date } = eventData;
            lastResetDate = last_reset_date;
        }

        // get all successful transactions
        const successStatusArray = ['SUCCESS', 'PAID'];
        const transactionsRef = collection(firestore, 'transactions');
        const w_2 = where('event_id', '==', eventId);
        const w_1 = where('status', 'in', successStatusArray);
        const w_3 = where('updated_at', '>=', lastResetDate);

        if (lastResetDate !== null) {
            q = query(transactionsRef, w_1, w_2, w_3);
        } else {
            q = query(transactionsRef, w_1, w_2);
        }

        const snap = await getDocs(q);

        const transactions = snap.docs.map(doc => {
            const item = doc.data();
            const { nominee_id, nominee_name, votes, vote_type, status, reference, is_completed, amount, gateway } = item;
            return { nominee_id, nominee_name, votes, vote_type, status, reference, is_completed, amount, gateway };
        });

        console.log("transactions", transactions.length);
        console.log("lastResetDate", dayjs(lastResetDate.toDate()).toISOString());

        // group transactions by nominee_id
        const groupedTransactions = transactions.reduce((acc, item) => {
            const { nominee_id, votes } = item;
            if (acc[nominee_id]) {
                acc[nominee_id].votes += votes;
            } else {
                acc[nominee_id] = { votes };
            }
            return acc;
        }, {});

        console.log("========= STARTED =========");
        for (const key in groupedTransactions) {
            if (Object.hasOwnProperty.call(groupedTransactions, key)) {
                const element = groupedTransactions[key];
                const { votes } = element;
                const nomineeRef = doc(firestore, 'events', eventId, 'nominees', key);
                await updateDoc(nomineeRef, { votes });
            }
        }
        console.log("========= ENDED =========");

        alert('done');

        response = {
            status: true,
            message: 'Transactions loaded',
            type: 'transactions.list.success',
            data: transactions,
            total: transactions.length,
            groups: groupedTransactions,
        }

    } catch (error) {
        response = {
            status: false,
            total: 0,
            message: error.message,
            type: 'transactions.list.error',
            error,
        }
    }

    return response;
};


export const eventPerformance = async (eventId) => {
    let response = {};

    try {
        // get all successful transactions
        const successStatusArray = ['SUCCESS', 'PAID'];
        const transactionsRef = collection(firestore, 'transactions');
        const w_2 = where('event_id', '==', eventId);
        const w_1 = where('status', 'in', successStatusArray);
        const q = query(transactionsRef, w_2, w_1);
        const snap = await getDocs(q);
        const transactions = snap.docs.map(doc => {
            const item = doc.data();
            const { nominee_id, nominee_name, votes, vote_type, status, reference, is_completed, amount, gateway } = item;
            return { nominee_id, nominee_name, votes, vote_type, status, reference, is_completed, amount, gateway };
        });


        // total votes
        const totalTransactions = transactions.reduce((acc, item) => {
            const { amount } = item;
            acc += amount;
            return acc;
        }, 0);


        // charged fees on total of 5%
        const chargedFees = totalTransactions * 0.05;

        // earnings total  - charged fees
        const earnings = totalTransactions - chargedFees;

        // all requisitions
        const requisitionsRef = collection(firestore, 'requisitions');
        const w_3 = where('event_id', '==', eventId);
        const w_4 = where('status', '==', 'APPROVED');
        const q_2 = query(requisitionsRef, w_3, w_4);
        const snap_2 = await getDocs(q_2);
        const requisitions = snap_2.docs.map(doc => {
            const item = doc.data();
            const { requested_amount } = item;
            return { requested_amount };
        });

        const totalApprovedRequisitions = requisitions.reduce((acc, item) => {
            const { requested_amount } = item;
            acc += requested_amount;
            return acc;
        }, 0);

        // get percentage for the event
        const eventRef = doc(firestore, 'events', eventId);
        const eventSnap = await getDoc(eventRef);
        const eventData = eventSnap.data();
        const { paystack } = eventData;
        const { percentage_charge } = paystack;


        // our total earnings
        const theirEarnings = earnings * (percentage_charge / 100);

        // their earnings
        const ourEarnings = earnings - theirEarnings;

        // outstanting earnings
        const outstandingEarnings = theirEarnings - totalApprovedRequisitions;

        const payload = {
            amount: Number(totalTransactions).toFixed(2),
            fees: Number(chargedFees).toFixed(2),
            earnings: Number(earnings).toFixed(2),
            their_earnings: Number(theirEarnings).toFixed(2),
            our_earnings: Number(ourEarnings).toFixed(2),
            withdrawals: Number(totalApprovedRequisitions).toFixed(2),
            voters: transactions.length,
            ourstanding_earnings: Number(outstandingEarnings).toFixed(2),
            percentages: {
                organizer: percentage_charge,
                smartkast: 100 - percentage_charge,
            },
            plan_earnings: {
                bronze: 0,
                silver: 0,
                gold: 0,
            },
            plan_counters: {
                bronze: 0,
                silver: 0,
                gold: 0,
            },
            plan_votes: {
                bronze: 0,
                silver: 0,
                gold: 0,
            },
            plan_prices: {
                bronze: 0,
                silver: 0,
                gold: 0,
            },
            promo_votes: 0,
            total_bulk_vote_earnings: 0,
            total_direct_vote_earnings: 0,
            bulk_vote: 0,
            direct_vote: 0,
            total_votes: 0,
        }

        response = {
            status: true,
            message: 'Transactions loaded',
            data: payload,
            total: snap.size,
            type: 'transactions.list.success',
            error: null,
        }


    } catch (error) {
        response = {
            status: false,
            message: error.message,
            data: null,
            total: 0,
            type: 'transactions.list.error',
            error,
        }
    }

    return response;
};

