<template>
    <section class="content">
        <header class="content__title">
            <h1>Requisitions</h1>
            <small>A list of all requests for withdrawals</small>
            <div class="actions"></div>
        </header>

        <Placeholder v-if="isLoading" />
        
        <div class="card" v-else-if="withdrawals.length > 0">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Currency</th>
                            <th>Amount</th>
                            <th>Event</th>
                            <th>Number</th>
                            <th>Account</th>
                            <th>Status</th>
                            <th class="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in withdrawals" :key="i">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item.date }}</td>
                            <td>{{ item.currency }}</td>
                            <td>{{ item.requested_amount }}</td>
                            <td>{{ item.event_title }}</td>
                            <td>{{ item.account.account_number }}</td>
                            <td>{{ item.account.settlement_bank }}</td>
                            <td>{{ item.status }}</td>
                            <td class="text-right">
                                <div v-if="!item.isProcessed">
                                    <button class="btn btn-success btn-sm ml-2" @click="onApprove(item, i)">
                                        <i class="zmdi zmdi-check mr-2"></i>
                                        Approve
                                    </button>
                                    <button class="btn btn-danger btn-sm ml-2" @click="onDispprove(item, i)">
                                        <i class="zmdi zmdi-check mr-2"></i>
                                        Disapprove
                                    </button>
                                </div>
                                <span v-else class="badge badge-success">Processed</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
        <NotFound message="No requisitions found" v-else />

    </section>
</template>

<script>
import { collection, getDocs, getFirestore, query, orderBy, doc, setDoc, increment } from '@firebase/firestore';
import dayjs from "dayjs"
const db = getFirestore();
export default {
    data(){
        return {
            isLoading: false,
            withdrawals: [],
        }
    },
    created(){
        this.loadWithdrawal();
    },
    methods: {
        async loadWithdrawal(){
            this.isLoading = true;
            const eventRef = collection(db, "requisitions");
            const q = query(eventRef, orderBy("requested_date", "desc"));
            const snapshot = await getDocs(q);
            const docs = snapshot.docs.map(doc => {
                const data = doc.data()
                data.id = doc.id
                data.date = dayjs(data.requested_date.toDate()).format('MMMM DD, YYYY');
                if (!('isProcessed' in data)) {
                    data.isProcessed = false;
                }
                return data
            });
            this.withdrawals = docs;
            this.isLoading = false;
        },
        onApprove(item, i) {
            this.$swal({
                title: 'APPROVE REQUEST',
                text: 'You will not be able to recover this record!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, approve it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
            }).then(async value => {
                if (value.isConfirmed) {
                    this.withdrawals[i].status = "APPROVED";
                    this.withdrawals[i].isProcessed = true;
                    this.$toast.success('Withdrawal approved successfully');
                    const { event_id, requested_amount, id } = item;
                    const amount = Number(requested_amount)
                    const eventPerfRef = doc(db, "event_performance", event_id);
                    const eventRef = doc(db, "events", event_id);
                    const requestRef = doc(db, "requisitions", id);
                    await setDoc(eventPerfRef, { withdrawals: increment(amount) }, { merge: true });
                    await setDoc(eventRef, { requesition_status: "APPROVED" }, { merge: true });
                    await setDoc(requestRef, { status: "APPROVED", isProcessed: true }, { merge: true });
                }
            });
        },
        onDispprove(item, i) {
            this.$swal({
                title: 'DISAPPPROVE REQUEST',
                text: 'You will not be able to recover this record!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, disapprove it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
            }).then(async value => {
                if (value.isConfirmed) {
                    this.withdrawals[i].status = "DISAPPROVED";
                    this.withdrawals[i].isProcessed = true;
                    this.$toast.success('Withdrawal disapproved successfully');
                    const { event_id, id } = item;
                    const eventRef = doc(db, "events", event_id);
                    const requestRef = doc(db, "requisitions", id);
                    await setDoc(eventRef, { requesition_status: "DISAPPROVED" }, { merge: true });
                    await setDoc(requestRef, { status: "DISAPPROVED", isProcessed: true }, { merge: true });
                }
            });
        },
    }
}
</script>