<template>
    <header class="header">
        <div class="navigation-trigger hidden-xl-up" @click="toggleMenu" :class="$store.getters.getMobileMenu ? 'toggled' : ''" data-ma-action="aside-open" data-ma-target=".sidebar">
            <div class="navigation-trigger__inner">
                <i class="navigation-trigger__line"></i>
                <i class="navigation-trigger__line"></i>
                <i class="navigation-trigger__line"></i>
            </div>
        </div>
        <div class="header__logo hidden-sm-down">
            <h1>
                <router-link to="/">SmartKast</router-link>
            </h1>
            <!-- <div>{{ menuStatus }}</div> -->
        </div>
        <ul class="top-nav">
            <li>
                <a @click="logout">
                    <i class="zmdi zmdi-power"></i>
                </a>
            </li>
        </ul>
    </header>
</template>

<script>
import { signOut, getAuth } from 'firebase/auth';
export default {
    data(){
        return {
            menuStatus: this.$store.getters.getMobileMenu
        }
    },
    methods: {
        logout(){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this transaction!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, logout!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                reverseButtons: true
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const auth = getAuth();
                    await signOut(auth);
                    this.$router.push('/login').catch(() => {});
                }    
            }).catch((err) => {
                console.log(err);
            });
        },
        toggleMenu(){
            this.menuStatus = !this.menuStatus;
            this.$store.dispatch('mobileMenu', this.menuStatus);
        },
    },
}
</script>