<template>
    <section class="content">
        <header class="content__title">
            <h1>Add Organizer</h1>
            <small>Create an event organizers</small>
            <div class="actions"></div>
        </header>
        <div class="card">
            <div class="card-body">
                <form @submit.prevent="saveOrganization">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <croppa 
                                            v-model="myCroppa"
                                            :width="200" 
                                            :height="200" 
                                            :quality="2"
                                            :accept="'image/*'"
                                            :prevent-white-space="true"
                                            />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <h4 class="card-title">Company Information</h4>
                                    <div class="form-group">
                                        <label for="">Name</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.name">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Address</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.address">
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="">Telephone</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.telephone">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Contact Person</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.contact_person">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Contact Telephone</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.contact_telephone">
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <h4 class="card-title">Settlement Account Information</h4>
                                    <div class="form-group">
                                        <label for="bank">Settlement Bank</label>
                                        <select id="bank" class="form-control" required v-model="organizer.settlement_bank">
                                            <option :value="bank.code" v-for="(bank, b) in banks" :key="b">{{ bank.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="pricing">Account Number</label>
                                        <input type="text" class="form-control" required v-model="organizer.account_number">
                                    </div>
                                    <h4 class="card-title">Login Credentials</h4>
                                    <div class="form-group">
                                        <label for="Email">Email</label>
                                        <input type="text" class="form-control" id="Email" required v-model="organizer.email">
                                        <small>Please ensure that this email is correct and has not already been used on the platform.</small>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-primary" :disabled="isProcessing">
                                            <i class="zmdi zmdi-save icon"></i>
                                            Save Organizer
                                            <Spinner v-if="isProcessing" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { createUserWithEmailAndPassword, getAuth } from '@firebase/auth';
import { PaystackGhanaBanks } from '../../services/banks';
import { validate } from 'email-validator';
import phonenumber from 'awesome-phonenumber';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { generatePassword } from '../../services/generateUid';

const auth = getAuth();
const firestore = getFirestore();
const storage = getStorage();

export default {
    data(){
        return {
            organizers: [],
            myCroppa: {},
            organizer: {
                name: "",
                address: "",
                email: "",
                password: "",
                telephone: "",
                contact_person: "",
                contact_telephone: "",
                logo: "",
                created_at: "",
                updated_at: "",
                uid: "",
                settlement_bank: "",
                account_number: "",
            },
            isProcessing: false,
            banks: [],
        }
    },
    created(){
        this.banks = PaystackGhanaBanks;
    },
    methods: {
        add(){
            this.$router.push('/organizers/add').catch(() => {});
        },
        async saveOrganization(){

            // start the processing
            this.isProcessing = true;

            // validate data
            const { email, telephone, contact_telephone, name } = this.organizer;

            if (email == "") {
                return this.$toast.error('Email is required');
            }

            if (!validate(email)) {
                return this.$toast.error('The email provided is invalid');
            }

            if (telephone == "") {
                return this.$toast.error('Telephone is required');
            }

            const PN_ORG = new phonenumber(telephone, 'GH');

            if (!PN_ORG.isValid()) {
                return this.$toast.error('The telephone provided is invalid');
            }

            if (contact_telephone == "") {
                return this.$toast.error('Contact Telephone is required');
            }

            const PN_CONTACT = new phonenumber(contact_telephone, 'GH');

            if (!PN_CONTACT.isValid()) {
                return this.$toast.error('The contact telephone provided is invalid');
            }

            const password = generatePassword();

            this.organizer.telephone = PN_ORG.getNumber();

            this.organizer.contact_telephone = PN_CONTACT.getNumber();

            this.organizer.created_at = dayjs().toDate();

            this.organizer.updated_at = dayjs().toDate();

            this.organizer.password = password;

            // set the logo on the organizer object
            const base64Data = this.myCroppa.generateDataUrl();

            let photo = "";

            if (base64Data.length > 0) {
                const uniqueId = uuidv4();
                const extension = '.jpg';
                const filename = `organizers/${uniqueId}${extension}`;
                const metadata = { contentType: 'image/jpeg' };
                const storageRef = ref(storage, filename);
                const snapshot = await uploadString(storageRef, base64Data, 'data_url', metadata);
                const url = await getDownloadURL(snapshot.ref);
                photo = url;
            }

            this.organizer.logo = photo;

            try {

                // create a user with the email and password
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const { user } = userCredential;
                const { uid } = user;
                this.organizer.uid = uid;

                // users collection reference
                const userRef = doc(firestore, 'users', uid);

                const userPayload = {
                    email,
                    password,
                    is_active: true,
                    is_verified: false,
                    name,
                    photo,
                    telephone,
                    type: 'organizer',
                    uid,
                }

                // save the data to the user collection
                await setDoc(userRef, userPayload);

                // save the organizer with the uid
                const organizerRef =  collection(firestore, 'organizers');

                const org_doc = await addDoc(organizerRef, this.organizer);

                const { id } = org_doc;

                this.$toast.success('Organizer added successfully');

                this.myCroppa.refresh();

                this.organizer = {
                    name: "",
                    address: "",
                    email: "",
                    password: "",
                    telephone: "",
                    contact_person: "",
                    contact_telephone: "",
                    logo: "",
                    created_at: "",
                    updated_at: "",
                    uid: "",
                    settlement_bank: "",
                    account_number: "",
                }

                this.isProcessing = false;

                const redirect = this.$route.query.redirect;
                if (typeof redirect !== 'undefined') {
                    if (redirect == 'true') {
                        this.$router.push('/events/add?organizer_id=' + id).catch(() => {});
                    }
                }

            } catch (error) {
                this.isProcessing = false;
                const { message } = error;
                this.$toast.error(message);
            }
        },
    }
}
</script>