<template>
    <section class="content">

        <header class="content__title">
            <h1>{{ $route.query.title }}</h1>
            <small>Provide information about the event</small>
            <div class="actions">
                <button class="btn btn-secondary" @click="$router.go(-1)">
                    <i class="zmdi zmdi-arrow-left mr-3"></i>
                    <span>Go Back</span>
                </button>
                <button class="btn btn-danger ml-3" type="button" :disabled="isUploading" @click="onSelectCategoryFile">
                    <i class="zmdi zmdi-upload mr-2"></i> 
                    Upload Nominees
                    <Spinner v-if="isUploading" />
                </button>
                <input 
                    type="file" 
                    class="category-file" 
                    ref="file" 
                    accept=".xlsx,.xls,.csv"
                    @change="onNomineeSelected">
            </div>
        </header>
        
        <div class="row">
            <div class="col-md-3" v-if="!isChangePhoto">
                <div class="card">
                    <div class="card-body">

                        <form @submit.prevent="saveNominee" v-show="!isEditNominee">
                            <div class="form-group">
                                <croppa 
                                    :width="260"
                                    :height="260"
                                    v-model="myCroppa"
                                    :quality="2"
                                    :accept="'image/*'"
                                    :prevent-white-space="true"
                                    :placeholder="`Choose a photo. Minimum size: 260 x 260`"
                                    :placeholder-font-size="12"
                                />
                            </div>
                            <div class="form-group">
                                <input type="text" placeholder="Name of Nominee" class="form-control" v-model="nominee.name">
                            </div>
                            <button class="btn btn-block btn-success" :disabled="isProcessing">
                                <i class="fa fa-save"></i> 
                                Save Nominee
                                <Spinner v-if="isProcessing" />
                            </button>
                        </form>

                        <form @submit.prevent="updateNominee" v-show="isEditNominee">
                            <div class="form-group">
                                <input type="text" placeholder="Name of Nominee" class="form-control" v-model="selectedNomimee.name">
                            </div>
                            <button class="btn btn-block btn-success" :disabled="isProcessing">
                                <i class="fa fa-save"></i> 
                                Update Nominee
                                <Spinner v-if="isProcessing" />
                            </button>
                        </form>

                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <Placeholder v-if="isLoading" />
                <div class="card"  v-else-if="nominees.length > 0">
                    <div class="card-body">
                        <h4 class="card-title">Contentants</h4>
                        <h6 class="card-subtitle">List of all contestants in this category</h6>
                    </div>
                    <div class="listview">
                        <div class="listview__item"  v-for="(item, i) in nominees" :key="i">
                            <progressive-img 
                                :src="item.photo"
                                :blur="0"
                                :placeholder="placeholder"
                                :fallback="placeholder"
                                class="listview__img" 
                                />
                            <div class="listview__content">
                                <div class="listview__heading">{{ item.name }}</div>
                                <p>Votes: {{ item.votes || 0 }}</p>
                                <p>{{ item.nominee_code }}</p>
                            </div>
                            <div class="actions">
                                
                                <button class="btn btn-warning ml-2" @click="editNominee(item)">
                                    <i class="zmdi zmdi-edit"></i>
                                    Edit
                                </button>
                                <button class="btn btn-primary ml-2" @click="onChangePhoto(item)">
                                    <i class="zmdi zmdi-upload"></i>
                                    Upload Photo
                                </button>
                                <button class="btn btn-danger ml-2" @click="onDeleteNominee(item, i)">
                                    <i class="zmdi zmdi-delete"></i>
                                    Delete 
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix mb-4"></div>
                </div>
                <NotFound message="No contentants found" v-else />
            </div>
            <div class="col-md-3" v-if="isChangePhoto">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="onChangeNomineePhoto">
                            <div class="form-group">
                                <croppa 
                                    :width="260"
                                    :height="260"
                                    ref="myCroppaChange"
                                    :quality="2"
                                    :accept="'image/*'"
                                    :prevent-white-space="true"
                                    :placeholder="`Choose a photo. Minimum size: 260 x 260`"
                                    :placeholder-font-size="12"
                                />
                            </div>
                            <button class="btn btn-block btn-success" :disabled="isChangingPhoto">
                                <i class="fa fa-save"></i> 
                                Save Nominee
                                <Spinner v-if="isChangingPhoto" />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { read, utils } from 'xlsx';
import { addDoc, collection, deleteDoc, doc, getDoc, getFirestore, onSnapshot, query, runTransaction, setDoc, where } from '@firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadString } from '@firebase/storage';
import { nanoid } from 'nanoid';
const db = getFirestore();
export default {
    data(){
        return {
            nominees: [],
            nominee: {
                name: "",
                photo: "",
                eventId: "",
                categoryId: "",
                votes: 0,
            },
            isProcessing: false,
            myCroppa: {},
            myCroppaChange: {},
            isLoading: false,
            placeholder: '/img/placeholder-square.jpg',
            unsubscribe: null,
            event_title: "",
            event_type: "",
            event_price: "",
            category: "",
            unique_id: "",
            live_votes: false,
            event: {},
            isUploading: false,
            isChangePhoto: false,
            isChangingPhoto: false,
            selectedNomimee: {},
            isEditNominee: false,
        }
    },
    created(){
        this.getNominees();
        this.load();
    },
    methods: {
        
        async getEvent(){
            const eventId = this.$route.params.eventId;
            const docRef = doc(db, "events", eventId);
            const snap = await getDoc(docRef);
            const data = snap.data()
            const { title } = data
            let unique_id
            if (!('unique_id' in data)) {
                unique_id = title.replaceAll(' ', '').toUpperCase().slice(0, 3);
            } else {
                unique_id = data.unique_id;
            }
            data.unique_id = unique_id;
            return data;
        },
        async getCategory(){
            const eventId = this.$route.params.eventId;
            const categoryId = this.$route.params.categoryId;
            const docRef = doc(db, "events", eventId, 'categories', categoryId);
            const snap = await getDoc(docRef);
            return snap.data().name;
        },
        async getNominees(){
            const eventId = this.$route.params.eventId;
            const categoryId = this.$route.params.categoryId;
            const docRef = collection(db, "events", eventId, 'nominees');
            const condition = where("categoryId", "==", categoryId);
            const q = query(docRef, condition);
            this.unsubscribe = onSnapshot(q, snapshot => {
                this.nominees = snapshot.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                }).sort((a, b) => {
                    return a.nominee_code > b.nominee_code ? -1 : 1;
                })
            })
        },
        async load(){
            this.isLoading = true;
            const p1 = await this.getEvent();
            const p2 = await this.getCategory();
            Promise.all([p1, p2]).then(values => {
                this.event = values[0].title;
                this.unique_id = values[0].unique_id;
                this.event = values[0];
                this.event_title = values[0].title;
                this.event_type = values[0].type;
                this.event_price = values[0].price;
                this.category = values[1];
                this.isLoading = false;
            }).catch(err => {
                this.isLoading = false;
                this.$toast.error(err.message);
            })
        },
        async saveNominee(){

            this.isProcessing = true;

            const { name } = this.nominee
            
            if (name.length == 0) {
                return this.$toast.error('Please enter a nominee name');
            }

            const base64 = this.myCroppa.generateDataUrl();

            if (base64.length == 0) {
                return this.$toast.error('Please upload an image');
            }

            const extension = '.jpg';
            
            const filename = `nominees/${nanoid()}${extension}`;
            
            const metadata = { contentType: 'image/jpeg' };
            
            const storage = getStorage();
            
            const storageRef = ref(storage, filename);

            const snapshot = await uploadString(storageRef, base64, 'data_url', metadata);
            
            const photo = await getDownloadURL(snapshot.ref);

            this.nominee.photo = photo;

            const db = getFirestore();

            const eventId = this.$route.params.eventId;

            const categoryId = this.$route.params.categoryId;

            const payload = {
                eventId,
                categoryId,
                name, 
                photo,
                votes: 0,
            }
            
            const eventRef = collection(db, 'events', eventId, 'nominees');
            
            const doc = await addDoc(eventRef, payload);

            const nomineeId = doc.id;

            await this.saveUssdNominee(nomineeId, name);
            
            this.nominee.name = "";

            this.myCroppa.refresh();
            
            this.$toast.success('Nominee added successfully');
            
            this.isProcessing = false;
        },
        async saveUssdNominee(nomineeId, name){
            const eventId = this.$route.params.eventId;
            const categoryId = this.$route.params.categoryId;
            try {
                runTransaction(db, async transaction => {
                    const counterRef = doc(db, 'event_unique_ids', this.unique_id);
                    const counterDoc = await transaction.get(counterRef)
                    let counter;
                    if (!counterDoc.exists()) {
                        counter = 0;
                    } else {
                        counter = counterDoc.data().nominees;
                    }
                    const nominee = counter + 1;
                    const nominee_code = this.unique_id + nominee;
                    const { live_votes, event_title, event_type, event_price, category } = this;
                    const ussdPayload = {
                        eventId,
                        nomineeId,
                        categoryId,
                        live_votes,
                        event_title,
                        event_type,
                        event_price,
                        category,
                        nominee_name: name,
                        nominee_code,
                        votes: 0,
                    }
                    const nominee_ref = doc(db, 'event_nominees', nominee_code)
                    const event_nominee_ref = doc(db, `events/${eventId}/nominees/${nomineeId}`)
                    transaction.set(counterRef, { nominees: nominee, unique_id: this.unique_id }, { merge: true });
                    transaction.set(nominee_ref, ussdPayload, { merge: true });
                    transaction.set(event_nominee_ref, { nominee_code }, { merge: true });
                })
            } catch (error) {
                this.$toast.error(error.message);
            }
        },
        onNomineeSelected($event){
            const file = $event.target.files ? $event.target.files[0] : null;
            const eventId = this.$route.params.eventId;
            const categoryId = this.$route.params.categoryId;
            if (file) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    /* Parse data */
                    const bstr = e.target.result;
                    // read the workbook
                    const wb = read(bstr, { type: 'binary' });
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = utils.sheet_to_json(ws, { header: 1 });

                    // delete the first row
                    data.shift();

                    this.isUploading = true;

                    for (const item of data) {

                        const name = item[0];
                        
                        const payload = {
                            eventId,
                            categoryId,
                            name, 
                            nominee_code: '',
                            photo: '',
                            votes: 0,
                        }

                        const eventRef = collection(db, 'events', eventId, 'nominees');
            
                        const doc = await addDoc(eventRef, payload);

                        const nomineeId = doc.id;

                        await this.saveUssdNominee(nomineeId, name);

                    }
                    this.isUploading = false;
                    this.$toast.success("Categories uploaded successfully");
                }

                reader.readAsBinaryString(file);
            }

        },
        onSelectCategoryFile(){
            this.$refs.file.click();
        },
        onChangePhoto(nominee){
            this.isChangePhoto = true;
            this.selectedNomimee = nominee;
        },
        async onChangeNomineePhoto(){

            const { id } = this.selectedNomimee;

            this.isChangePhoto = false;
            
            this.$toast.success('Nominee photo changed successfully');
            
            const eventId = this.$route.params.eventId;

            const base64 = this.$refs.myCroppaChange.generateDataUrl();

            if (base64.length == 0) {
                return this.$toast.error('Please upload an image');
            }

            this.nominees.find(nominee => nominee.id == id).photo = base64;

            const extension = '.jpg';
            
            const filename = `nominees/${nanoid()}${extension}`;
            
            const metadata = { contentType: 'image/jpeg' };
            
            const storage = getStorage();
            
            const storageRef = ref(storage, filename);

            const snapshot = await uploadString(storageRef, base64, 'data_url', metadata);
            
            const photo = await getDownloadURL(snapshot.ref);

            const eventRef = doc(db, 'events', eventId, 'nominees', id);
            
            await setDoc(eventRef, { photo }, { merge: true });

            this.$refs.myCroppaChange.refresh();

        },

        async updateNominee(){

            this.isProcessing = true;

            const { name, id, nominee_code } = this.selectedNomimee
            
            if (name.length == 0) {
                return this.$toast.error('Please enter a nominee name');
            }            

            const eventId = this.$route.params.eventId;

            const payload = { name }
            
            const eventRef = doc(db, 'events', eventId, 'nominees', id);

            await setDoc(eventRef, payload, { merge: true });

            const nomineeRef = doc(db, 'event_nominees', nominee_code);

            await setDoc(nomineeRef, { nominee_name: name }, { merge: true });

            this.isEditNominee = false;
            
            this.$toast.success('Nominee updated successfully');
            
            this.isProcessing = false;
        },
        editNominee(nominee){
            this.isEditNominee = true;
            this.selectedNomimee = nominee;
        },

        async onDeleteNominee(nominee, index){
            const { id, eventId } = nominee;
            this.$swal({
                title: 'Delete Nominee',
                text: `Are you sure you want to delete this nominee?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isUpdating = true;
                    this.nominees.splice(index, 1);
                    const ref = doc(db, `events`, eventId, 'nominees', id);
                    await deleteDoc(ref);
                }
            });
        },

    },
    beforeDestroy(){
        if (this.unsubscribe != null) {
            this.unsubscribe();
        }
    }
}
</script>