const ghanabanks = [
    {
        "name": "Absa Bank Ghana Ltd",
        "slug": "absa-bank-ghana-ltd",
        "code": "030100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 76,
        "createdAt": "2020-04-23T14:26:59.000Z",
        "updatedAt": "2020-04-23T14:26:59.000Z"
    },
    {
        "name": "Access Bank",
        "slug": "access-bank-ghana",
        "code": "280100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 31,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2020-04-17T09:00:41.000Z"
    },
    {
        "name": "ADB Bank Limited",
        "slug": "adb-bank-limited",
        "code": "080100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 32,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2019-10-18T11:53:08.000Z"
    },
    {
        "name": "AirtelTigo",
        "slug": "atl-mobile-money",
        "code": "ATL",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "mobile_money",
        "id": 29,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2020-01-24T10:01:06.000Z"
    },
    {
        "name": "ARB Apex Bank",
        "slug": "arb-apex-bank",
        "code": "070101",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 182,
        "createdAt": "2021-08-03T08:53:40.000Z",
        "updatedAt": "2021-08-03T08:53:40.000Z"
    },
    {
        "name": "Bank of Africa Ghana",
        "slug": "bank-of-africa-ghana",
        "code": "210100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 33,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Bank of Baroda Ghana Limited",
        "slug": "bank-of-baroda-ghana-limited",
        "code": "260100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 34,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Bank of Ghana",
        "slug": "bank-of-ghana",
        "code": "010100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 77,
        "createdAt": "2020-04-23T14:26:59.000Z",
        "updatedAt": "2020-04-23T14:26:59.000Z"
    },
    {
        "name": "Barclays Bank of Ghana Limited",
        "slug": "barclays-bank-of-ghana-limited",
        "code": "030100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 36,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2019-10-18T11:52:41.000Z"
    },
    {
        "name": "BSIC Ghana Limited",
        "slug": "bsic-ghana-limited",
        "code": "270100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 35,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "CAL Bank Limited",
        "slug": "cal-bank-limited",
        "code": "140100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 37,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Consolidated Bank Ghana Limited",
        "slug": "consolidated-bank-ghana-limited",
        "code": "300100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 78,
        "createdAt": "2020-04-23T14:26:59.000Z",
        "updatedAt": "2020-04-23T14:26:59.000Z"
    },
    {
        "name": "Ecobank Ghana Limited",
        "slug": "ecobank-ghana-limited",
        "code": "130100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 38,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Energy Bank Ghana Limited",
        "slug": "energy-bank-ghana-limited",
        "code": "290100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 39,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "FBNBank Ghana Limited",
        "slug": "fbnbank-ghana-limited",
        "code": "200100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 40,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Fidelity Bank Ghana Limited",
        "slug": "fidelity-bank-ghana-limited",
        "code": "240100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 41,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "First Atlantic Bank Limited",
        "slug": "first-atlantic-bank-limited",
        "code": "170100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 42,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "First National Bank Ghana Limited",
        "slug": "first-national-bank-ghana-limited",
        "code": "330100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 43,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "GCB Bank Limited",
        "slug": "gcb-bank-limited",
        "code": "040100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 44,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2019-10-18T11:53:01.000Z"
    },
    {
        "name": "GHL Bank",
        "slug": "ghl-bank-ghana",
        "code": "390100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 79,
        "createdAt": "2020-04-23T14:26:59.000Z",
        "updatedAt": "2020-04-23T14:26:59.000Z"
    },
    {
        "name": "GN Bank",
        "slug": "gn-bank",
        "code": "320100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 45,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Guaranty Trust Bank (Ghana) Limited",
        "slug": "guaranty-trust-bank-(ghana)-limited",
        "code": "230100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 46,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Heritage Bank Ghana",
        "slug": "heritage-bank-ghana",
        "code": "370100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 48,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "HFC Bank Ghana Limited",
        "slug": "hfc-bank-ghana-limited",
        "code": "110100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 47,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "MTN",
        "slug": "mtn-mobile-money",
        "code": "MTN",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "mobile_money",
        "id": 28,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2019-10-22T11:04:46.000Z"
    },
    {
        "name": "National Investment Bank Limited",
        "slug": "national-investment-bank-limited",
        "code": "050100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 49,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2019-10-18T11:53:16.000Z"
    },
    {
        "name": "OmniBank Ghana Limited",
        "slug": "omnibank-ghana-limited",
        "code": "360100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 61,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Premium Bank Limited",
        "slug": "premium-bank-limited",
        "code": "350100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 60,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Prudential Bank Limited",
        "slug": "prudential-bank-limited",
        "code": "180100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 50,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Services Integrity Savings and Loans",
        "slug": "services-integrity-savings-and-loans",
        "code": "300361",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 80,
        "createdAt": "2020-04-23T14:26:59.000Z",
        "updatedAt": "2020-04-23T14:26:59.000Z"
    },
    {
        "name": "Société Générale Ghana Limited",
        "slug": "société-générale-ghana-limited",
        "code": "090100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 51,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2019-10-18T11:53:21.000Z"
    },
    {
        "name": "Sovereign Bank Ghana",
        "slug": "sovereign-bank-ghana",
        "code": "340100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 59,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Stanbic Bank Ghana Limited",
        "slug": "stanbic-bank-ghana-limited",
        "code": "190100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 52,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Standard Chartered Bank Ghana Limited",
        "slug": "standard-chartered-bank-ghana-limited",
        "code": "020100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 53,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2019-10-18T11:53:29.000Z"
    },
    {
        "name": "The Royal Bank Limited",
        "slug": "the-royal-bank-limited",
        "code": "300100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 54,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "UniBank Ghana Limited",
        "slug": "unibank-ghana-limited",
        "code": "220100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 55,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "United Bank for Africa Ghana Limited",
        "slug": "united-bank-for-africa-ghana-limited",
        "code": "060100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 56,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2019-10-18T11:53:35.000Z"
    },
    {
        "name": "Universal Merchant Bank Ghana Limited",
        "slug": "universal-merchant-bank-ghana-limited",
        "code": "100100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 57,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    },
    {
        "name": "Vodafone",
        "slug": "vod-mobile-money",
        "code": "VOD",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "mobile_money",
        "id": 66,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2019-10-22T11:05:08.000Z"
    },
    {
        "name": "Zenith Bank Ghana",
        "slug": "zenith-bank-ghana",
        "code": "120100",
        "longcode": "",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "is_deleted": null,
        "country": "Ghana",
        "currency": "GHS",
        "type": "ghipss",
        "id": 58,
        "createdAt": "2018-03-29T12:54:59.000Z",
        "updatedAt": "2018-03-29T12:54:59.000Z"
    }
];


export const PaystackGhanaBanks = ghanabanks.map(bank => {
    return {
        code: bank.code,
        name: bank.name,
        slug: bank.slug,
        type: bank.type,
        currency: bank.currency,
        active: bank.active,
    }
})