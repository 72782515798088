<template>
    <section class="content">
        <header class="content__title">
            <h1>Add Organizer</h1>
            <small>Create an event organizers</small>
            <div class="actions"></div>
        </header>

        <div class="card">
            <div class="card-body">
                
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="aside-photo" >
                                <div class="mb-3">
                                    <img :src="organizer.logo" alt="" width="200" height="200">
                                </div>
                                <div class="mb-3">
                                    <croppa 
                                        v-model="myCroppa"
                                        :width="200" 
                                        :height="200" 
                                        :quality="2"
                                        :accept="'image/*'"
                                        :prevent-white-space="true"
                                        @file-choose="onChooseFile"
                                        @image-remove="onRemoveImage"
                                        />
                                </div>
                                <div>
                                    <button
                                        class="btn btn-success btn-block"
                                        :disabled="!isChooseFile || isUploading"
                                        @click="savePhoto"
                                    >
                                        <i class="zmdi zmdi-upload"></i>
                                        Upload Photo
                                        <Spinner v-if="isUploading" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <form @submit.prevent="updateOrganizer">
                            <div class="row">
                                <div class="col-md-6">
                                    <h4 class="card-title">Company Information</h4>
                                    <div class="form-group">
                                        <label for="">Name</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.name">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Address</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.address">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Telephone</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.telephone">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Contact Person</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.contact_person">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Contact Telephone</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.contact_telephone">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <h4 class="card-title">Settlement Account Information</h4>
                                    <div class="form-group">
                                        <label for="bank">Settlement Bank</label>
                                        <select id="bank" class="form-control" required v-model="organizer.settlement_bank">
                                            <option :value="bank.code" v-for="(bank, b) in banks" :key="b">{{ bank.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="pricing">Account Number</label>
                                        <input type="text" class="form-control" required v-model="organizer.account_number">
                                    </div>
                                    <h4 class="card-title">Login Credentials</h4>
                                    <div class="form-group">
                                        <label for="">Email</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.email" readonly>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Password</label>
                                        <input type="text" class="form-control" id="" required v-model="organizer.password" readonly>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-primary" :disabled="isProcessing">
                                            <i class="zmdi zmdi-save icon"></i>
                                            Save Organizer
                                            <Spinner v-if="isProcessing" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>
        </div>

        <!-- <pre>{{ organizer}}</pre> -->
        
    </section>
</template>

<script>
import { getFirestore, setDoc, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { PaystackGhanaBanks } from '../../services/banks';
import dayjs from 'dayjs';
const { v4: uuidv4 } = require('uuid');

// initialize the firestore
const firestore = getFirestore();
export default {
    data(){
        return {
            organizers: [],
            myCroppa: {},
            organizer: {
                name: "",
                address: "",
                email: "",
                telephone: "",
                contact_person: "",
                contact_telephone: "",
                logo: "",
                created_at: "",
                updated_at: "",
                uid: "",
                settlement_bank: "",
                account_number: "",
            },
            banks: [],
            isProcessing: false,
            isUploading: false,
            isChooseFile: false,
        }
    },
    created(){
        this.getOrganizer();
        this.banks = PaystackGhanaBanks;
    },
    methods: {
        add(){
            this.$router.push('/organizers/add').catch(() => {});
        },
        
        async getOrganizer(){
            const { organizerId } = this.$route.params;
            const organizerRef = doc(firestore, 'organizers', organizerId);
            const organizer = await getDoc(organizerRef);
            this.organizer = organizer.data();
        },

        async updateOrganizer(){
            this.isProcessing = true;
            const { organizerId } = this.$route.params;
            this.organizer.updated_at = dayjs().toDate();
            const organizerRef = doc(firestore, 'organizers', organizerId);
            await setDoc(organizerRef, this.organizer, { merge: true });
            this.$toast.success('Organizer updated successfully');
            this.isProcessing = false;
        },

        onChangePhoto() {
            this.isChangePhoto = true;
        },
        onSelectFile() {
            this.$refs.myCroppa.chooseFile();
        },
        onChooseFile() {
            this.isChooseFile = true;
        },
        onRemoveImage() {
            this.isChooseFile = false;
        },
        async savePhoto() {

            this.isUploading = true;

            const base64 = this.myCroppa.generateDataUrl();

            if (base64.length == 0) {
                this.isUploading = false;
                return this.$toast.error("Please upload an image");
            }

            const uniqueId = uuidv4();

            const extension = ".jpg";

            const filename = `organizers/${uniqueId}${extension}`;

            const metadata = { contentType: "image/jpeg" };

            const storage = getStorage();

            const storageRef = ref(storage, filename);

            const snapshot = await uploadString(
                storageRef,
                base64,
                "data_url",
                metadata
            );

            const logo = await getDownloadURL(snapshot.ref);

            const { organizerId } = this.$route.params;

            const organizerRef = doc(firestore, "organizers", organizerId);

            await setDoc(organizerRef, { logo }, { merge: true });

            this.organizer.logo = logo;

            this.myCroppa.refresh();

            this.$toast.success("Photo saved successfully");

            this.isUploading = false;

        },
    }
}
</script>