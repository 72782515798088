<template>
    <section class="content">
        <header class="content__title">
            <h1>Pricing</h1>
            <small>A list of all event organizers</small>

            <div class="actions">
                <button class="btn btn-success" @click="add">
                    <i class="zmdi zmdi-plus"></i> 
                    Add Organizer
                </button>
            </div>
        </header>

        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="savePackage">
                            <div class="form-group">
                                <label for="">Event Name</label>
                                <input type="text" class="form-control" id="" >
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">Plan</label>
                                        <input type="text" class="form-control" id="" >
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="">Price</label>
                                        <input type="text" class="form-control" id="" >
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="">Votes</label>
                                        <input type="text" class="form-control" id="" >
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group text-right">
                                        <button class="btn btn-primary">
                                            <i class="zmdi zmdi-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button class="btn btn-primary">
                                    <i class="zmdi zmdi-save icon"></i>
                                    Save Package
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            organizers: [],
        }
    },
    methods: {
        add(){
            this.$router.push('/organizers/add').catch(() => {});
        },
        savePackage(){

        }
    }
}
</script>