<template>
    <section class="content">
        <header class="content__title">
            <h1>Users</h1>
            <small>A list of all users and their roles</small>
            <div class="actions"></div>
        </header>

        <div class="card">
            <div class="card-body">
                <form @submit.prevent="onSaveUser">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Name</label>
                                <input type="text" class="form-control" id="" required v-model="user.name">
                            </div>
                            <div class="form-group">
                                <label for="">Telephone</label>
                                <input type="text" class="form-control" id="" required v-model="user.telephone">
                            </div>
                            <div class="form-group">
                                <label for="">Email</label>
                                <input type="text" class="form-control" id="" required v-model="user.email">
                            </div>
                            <div class="form-group">
                                <label for="">Password</label>
                                <input type="text" class="form-control" id="" required v-model="user.password">
                            </div>
                            <div class="form-group">
                                <button class="btn btn-primary" :disabled="isProcessing">
                                    <i class="zmdi zmdi-save icon"></i>
                                    Save User
                                    <Spinner v-if="isProcessing" />
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="">Logo</label>
                            <div class="form-group">
                                <croppa 
                                    v-model="myCroppa"
                                    :width="200" 
                                    :height="200" 
                                    :quality="2"
                                    :accept="'image/*'"
                                    :prevent-white-space="true"
                                    />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        
    </section>
</template>

<script>
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { createUserWithEmailAndPassword, getAuth } from '@firebase/auth';
import { nanoid } from 'nanoid';
import { validate } from 'email-validator';
import phonenumber from 'awesome-phonenumber';
import dayjs from 'dayjs';
export default {
    data(){
        return {
            myCroppa: {},
            user: {
                name: "",
                telephone: "",
                email: "",
                password: "",
                photo: "",
                uid: "",
                created_at: "",
                updated_at: "",
            },
            isProcessing: false,
        }
    },
    methods: {
        add(){
            this.$router.push('/organizers/add').catch(() => {});
        },
        async onSaveUser(){

            // start the processing
            this.isProcessing = true;

            // validate data
            const { email, telephone, name, password } = this.user;

            if (email == "") {
                return this.$toast.error('Email is required');
            }

            if (!validate(email)) {
                return this.$toast.error('The email provided is invalid');
            }

            if (telephone == "") {
                return this.$toast.error('Telephone is required');
            }

            const PN_ORG = new phonenumber(telephone, 'GH');

            if (!PN_ORG.isValid()) {
                return this.$toast.error('The telephone provided is invalid');
            }

            this.user.telephone = PN_ORG.getNumber();

            this.user.created_at = dayjs().toDate();

            this.user.updated_at = dayjs().toDate();


            // set the logo on the user object
            const base64Data = this.myCroppa.generateDataUrl();

            let photo = "";

            if (base64Data.length > 0) {
                const uniqueId = nanoid();
                const extension = '.jpg';
                const filename = `users/${uniqueId}${extension}`;
                const metadata = { contentType: 'image/jpeg' };
                const storage = getStorage();
                const storageRef = ref(storage, filename);
                const snapshot = await uploadString(storageRef, base64Data, 'data_url', metadata);
                const url = await getDownloadURL(snapshot.ref);
                photo = url;
            }

            this.user.photo = photo;

            try {
                
                // create a user with the email and password
                const auth = getAuth();
                
                const userCredential = await createUserWithEmailAndPassword(auth, email, password)

                const { user } = userCredential;

                const { uid } = user;

                this.user.uid = uid;

                // initialize the firestore
                const firestore = getFirestore();

                // users collection reference
                const userRef = doc(firestore, 'users', uid);

                const userPayload = {
                    email,
                    password,
                    is_active: true,
                    is_verified: false,
                    name,
                    photo,
                    telephone,
                    type: 'administrator',
                    uid,
                }

                // save the data to the user collection
                await setDoc(userRef, userPayload);

                // save the user with the uid
                const organizerRef =  collection(firestore, 'organizers');

                await addDoc(organizerRef, this.user);

                this.$toast.success('Organizer added successfully');

                this.myCroppa.refresh();

                this.user = {
                    name: "",
                    telephone: "",
                    email: "",
                    password: "",
                    photo: "",
                    uid: "",
                    created_at: "",
                    updated_at: "",
                }

                this.isProcessing = false;

            } catch (error) {
                this.isProcessing = false;
                const { message } = error;
                this.$toast.error(message);
            }
        },
    }
}
</script>