<template>
    <section class="content">
        <header class="content__title">
            <h1>Nominations</h1>
            <small>A List of Nominees</small>
            <div class="actions">
                <button class="btn btn-danger" @click="goBack">
                    Back
                </button>
            </div>
        </header>
        <div class="toolbar">
            <div class="toolbar__nav">
                <router-link to="/nominations/t/artistes">Artistes</router-link>
                <router-link to="/nominations/t/albums">Albums</router-link>
                <router-link to="/nominations/t/music-videos">MusicVideos</router-link>
            </div>
        </div>
        <div class="card">
            
            <div v-if="item.entry_option == 'single'" class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <img :src="item.artist_photo || placeholder" alt="." class="nominee-photo">
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="">audio_link</label>
                            <VueAudio :file="item.audio_link" />
                        </div>
                        <div class="form-group">
                            <label for="">song_link</label>
                            <div>
                                <button class="btn btn-danger" @click="goToSongLink(item.song_link)" type="button">
                                    Go To Song Link
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">artist_name</label>
                            <p class="lead">{{ item.artist_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">song_title</label>
                            <p class="lead">{{ item.song_title }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">country</label>
                            <p class="lead">{{ item.country }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">release_date</label>
                            <p class="lead">{{ item.release_date }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">producer_name</label>
                            <p class="lead">{{ item.producer_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">stage_name</label>
                            <p class="lead">{{ item.stage_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">music_genre</label>
                            <p class="lead">{{ item.music_genre }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">management_contact</label>
                            <p class="lead">{{ item.management_contact }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">featured_artist</label>
                            <p class="lead">{{ item.featured_artist }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">record_label</label>
                            <p class="lead">{{ item.record_label }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">management_company</label>
                            <p class="lead">{{ item.management_company }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">release_date</label>
                            <p class="lead">{{ item.release_date }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">producer_telephone</label>
                            <p class="lead">{{ item.producer_telephone }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">region</label>
                            <p class="lead">{{ item.region }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">producer_name</label>
                            <p class="lead">{{ item.producer_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">social_twitter</label>
                            <p class="lead">{{ item.social_twitter }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">social_youtube</label>
                            <p class="lead">{{ item.social_youtube }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">social_instagram</label>
                            <p class="lead">{{ item.social_instagram }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">created_at</label>
                            <p class="lead">{{ item.created }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="item.entry_option == 'album'" class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <img :src="item.album_cover || placeholder" alt="." class="nominee-photo">
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="">artist_name</label>
                            <p class="lead">{{ item.artist_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">album_title</label>
                            <p class="lead">{{ item.album_title }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">release_date</label>
                            <p class="lead">{{ item.release_date }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">release_date</label>
                            <p class="lead">{{ item.release_date }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">album_link_1</label>
                            <p class="lead">
                                <a :href="item.album_link_1" target="blank">Album Link 1</a>
                            </p>
                        </div>
                        <div class="form-group">
                            <label for="">album_link_2</label>
                            <p class="lead">
                                <a :href="item.album_link_2" target="blank">Album Link 2</a>
                            </p>
                        </div>
                        <div class="form-group">
                            <label for="">album_link_3</label>
                            <p class="lead">
                                <a :href="item.album_link_3" target="blank">Album Link 3</a>
                            </p>
                        </div>
                        <div class="form-group">
                            <label for="">album_link_4</label>
                            <p class="lead">
                                <a :href="item.album_link_4" target="blank">Album Link 4</a>
                            </p>
                        </div>
                        <div class="form-group">
                            <label for="">album_link_5</label>
                            <p class="lead">
                                <a :href="item.album_link_5" target="blank">Album Link 5</a>
                            </p>
                        </div>
                        <div class="form-group">
                            <label for="">country</label>
                            <p class="lead">{{ item.country_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">region</label>
                            <p class="lead">{{ item.artist_region }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">created_at</label>
                            <p class="lead">{{ item.created }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="item.entry_option == 'music-video'" class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <img :src="item.album_cover || placeholder" alt="." class="nominee-photo">
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="">artist_name</label>
                            <p class="lead">{{ item.artist_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">stage_name</label>
                            <p class="lead">{{ item.stage_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">album_title</label>
                            <p class="lead">{{ item.album_title }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">release_date</label>
                            <p class="lead">{{ item.release_date }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">album_link</label>
                            <p class="lead">{{ item.album_link }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">country</label>
                            <p class="lead">{{ item.country_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">artist_region</label>
                            <p class="lead">{{ item.artist_region }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">director_name</label>
                            <p class="lead">{{ item.director_name }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">director_telephone</label>
                            <p class="lead">{{ item.director_telephone }}</p>
                        </div>
                        <div class="form-group">
                            <label for="">created_at</label>
                            <p class="lead">{{ item.created }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <pre>{{ item }}</pre> -->

        </div>
    </section>
</template>

<script>
import { getArtiste, getAlbum, getMusicVideo } from '../../services/Nominations';
import VueAudio from 'vue-audio';

export default {
    data() {
        return {
            placeholder: '/img/placeholder-square.jpg',
            isLoading: false,
            item: {},
            type: this.$route.params.nominationType,
        }
    },
    mounted(){
        this.onLoadArtiste();
    },
    components: {
        VueAudio,
    },
    methods: {
        
        goBack(){
            this.$router.push("/nominations/t/" + this.type);
        },
        PurifyLink(text) {
            var testUrl = text.match(/'(http:[^\s]+)'/)
            return testUrl;
        },
        goToSongLink(song_link){
            window.open(song_link, '_blank');
        },
        async onLoadArtiste(){
            try {
                this.isLoading = true;
                const { nomineeId, nominationType } = this.$route.params;
                if (nominationType == 'artistes') {
                    const { status, data } = await getArtiste(nomineeId)
                    if (status) {
                        this.item = {}
                        this.item = data
                    }
                } else if (nominationType == 'albums') {
                    const { status, data } = await getAlbum(nomineeId)
                    if (status) {
                        this.item = {}
                        this.item = data
                    }
                } else if (nominationType == 'music-videos') {
                    const { status, data } = await getMusicVideo(nomineeId)
                    if (status) {
                        this.item = {}
                        this.item = data
                    }
                }
                this.isLoading = false;
            } catch (error) {
                this.item = {}
                this.isLoading = false;
            }
        },
    },
}
</script>

<style scoped>
.nominee-photo {
    width: 300px;
    height: auto;
    border-radius: 1%;
}
</style>