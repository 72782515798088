<template>
    <section class="content">
        <header class="content__title">
            <h1>Transactions</h1>
            <small>Load all Transactions</small>
            <div class="actions">
                <button class="btn btn-success" @click="onAction('list')">
                    Registrations
                </button>
            </div>
        </header>

        <Placeholder v-if="isLoading" />

        <div class="row" v-else-if="values.length > 0">
            <div class="col-md-12">
                <div class="card">
                    <div class="table-responsive">
                        <table class="table no-margin">
                            <thead>
                                <tr>
                                    <th v-for="header in headers" :key="header">{{ header }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in values" :key="i">
                                    <td v-for="header in headers" :key="header">
                                        <span v-if="['payment_status', 'approval_status'].includes(header)">
                                            {{ item[header] }}
                                        </span>
                                        <span v-else-if="header == 'price'">GHS {{ item[header] }}</span>
                                        <span v-else>{{ item[header] }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        
        <NotFound message="No registrations found" v-else />
        
    </section>
</template>

<script>
import { collection, getDocs, getFirestore, orderBy, query, where } from '@firebase/firestore';
import dayjs from 'dayjs';

const db = getFirestore();


export default {
    data() {
        return {
            isLoading: false,
            items: [],
        }
    },
    mounted() {
        this.onAction('load');
    },
    methods: {
        flattenObject(obj, parent = '', res = {}) {
            for (let key in obj) {
                if ([key] in obj) {
                    let propName = parent ? parent + '_' + key : key;
                    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                        this.flattenObject(obj[key], propName, res);
                    } else {
                        res[propName] = obj[key];
                    }
                }
            }
            return res;
        },
        async onAction(action) {
            if (action == 'load') {
                try {
                    this.isLoading = true;
                    let r = collection(db, 'registration_requests');
                    let w_1 = where('payment_status', 'in', ['paid', 'success', 'completed']);
                    let w_2 = where('event_id', '==', this.$route.params.registrationId)
                    let o = orderBy('created_at', 'desc');
                    let q = query(r, w_1, w_2, o);
                    let { empty, docs } = await getDocs(q);
                    if (!empty) {
                        let data = docs.map(item => {
                            let d = item.data();
                            d.id = item.id;
                            d.created = dayjs(d.created_at.toDate()).format('DD/MM/YYYY HH:mm A');
                            return d;
                        });
                        this.items = data.map((item) => {
                            let { payment_status, approval_status, price, telephone, register, created } = item;
                            let _result = { created, price, telephone, register, payment_status, approval_status };
                            return this.flattenObject(_result);
                        });
                    } else {
                        console.log('empty');
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isLoading = false;
                }
            }
            if (action == 'list') {
                this.$router.push(`/registrations`);
            }
        },
    },
    computed: {

        headers() {
            return Object.keys(this.items[0]);
        },

        values() {
            let _headers = this.headers;
            let reorderedArray = this.items.map(item => {
                let reorderedItem = {};
                _headers.forEach(header => {
                    reorderedItem[header] = item[header];
                });
                return reorderedItem;
            });
            return reorderedArray;
        },

    }
}
</script>