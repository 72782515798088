<template>
    <div class="login">
        <div class="login__block active" id="l-login">
            <div class="login__block__header">
                <i class="zmdi zmdi-account-circle"></i>
                Hi there! Please Sign in
            </div>
            <form @submit.prevent="login">
                <div class="login__block__body">
                    <div class="form-group form-group--centered">
                        <label>Email Address</label>
                        <input type="text" class="form-control" v-model="email" required>
                        <i class="form-group__bar"></i>
                    </div>

                    <div class="form-group form-group--centered">
                        <label>Password</label>
                        <input type="password" class="form-control" v-model="password" required>
                        <i class="form-group__bar"></i>
                    </div>

                    <button class="btn btn-success btn-block" type="submit" :disabled="isProcessing">
                        Login
                        <Spinner v-if="isProcessing" />
                    </button>

                    <button class="btn btn-block btn-default" type="button" @click="forgot">
                        Forgot Password
                    </button>

                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { validate } from 'email-validator';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore"
const db = getFirestore();

export default {
    data() {
        return {
            email: "",
            password: "",
            isProcessing: false,
        };
    },
    methods: {
        login() {
            const { email, password } = this;
            if (email == "") {
                return this.$toast.error("Email is required");
            }
            if (!validate(email)) {
                return this.$toast.error("Invalid email address");
            }
            if (password == "") {
                return this.$toast.error("Password is required");
            }
            if (password.length < 6) {
                return this.$toast.error("Password must be at least 6 characters");
            }
            this.isProcessing = true;
            const auth = getAuth();
            signInWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    const { user } = userCredential;
                    const { uid } = user;
                    try {
                        const userRef = doc(db, 'users', uid);
                        const snapshot = await getDoc(userRef);
                        const data = snapshot.data();
                        
                        if (!data) {
                            return this.$toast.error("User not found");
                        }
                        this.$router.push('/').catch(() => { });
                        
                        this.$store.dispatch("setUser", data);
                        
                    } catch (error) {
                        console.log({ error });
                    } finally {
                        this.isProcessing = false;
                    }
                })
                .catch(() => {
                    this.isProcessing = false;
                    return this.$toast.error("User not found");
                });
        },
        forgot() {
            this.$router.push('/forgot-password').catch(() => { });
        },
    }
}
</script>