<template>
    <section class="content">
        <header class="content__title">
            <h1>Events</h1>
            <small>Welcome to the unique Material Design admin web app experience!</small>

            <div class="actions">
                <button class="btn btn-primary ml-2" @click="downloadTransactions" :disabled="isExporting">
                    <span>Export Transactions</span>
                    <Spinner v-if="isExporting" />
                </button>


                <button class="btn btn-danger ml-2" @click="onRecalcTransaction" :disabled="isRecalcTrans">
                    Recalculate & Auto Credit
                    <Spinner v-if="isRecalcTrans" />
                </button>



                <input type="text" v-model="nomineeId">
                <button class="btn btn-secondary ml-2" @click="onVerifyVotes" :disabled="isVerifying">
                    Verify Votes
                    <Spinner v-if="isVerifying" />
                </button>

                <!-- 
                <button class="btn btn-danger ml-2" @click="onRestorePerformance" :disabled="isReseting">
                    restorePerformance
                    <Spinner v-if="isReseting" />
                </button> 
                -->

            </div>
        </header>

        <!-- <pre>{{ sunday10pm }}</pre> -->


        <ToolbarEvents />

        <Placeholder v-if="isLoading" />

        <div v-else-if="events.length > 0">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <!-- <th>Name</th> -->
                                <th>Amount</th>
                                <!-- <th>Vote Type</th> -->
                                <th>Votes</th>
                                <th>Nominee</th>
                                <th>Nominee ID</th>
                                <th>Reference</th>
                                <th>Status</th>
                                <th>Gateway</th>
                                <th>Channel</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(event, i) in events" :key="i">
                                <td>{{ event.created }}</td>
                                <!-- <td>{{ event.name }}</td> -->
                                <td>GHS {{ event.amount }}</td>
                                <!-- <td>{{ event.vote_type || 'N/A' }}</td> -->
                                <td>{{ event.votes || 'N/A' }}</td>
                                <td>{{ event.nominee_name }}</td>
                                <td>{{ event.nomineeId }}</td>
                                <td>{{ event.reference }}</td>
                                <td>{{ event.status }}</td>
                                <td>{{ event.gateway || 'N/A' }}</td>
                                <td>{{ event.channel || 'N/A' }}</td>
                                <td>
                                    <button type="button" class="btn btn-sm"
                                        :class="event.email == '' || event.is_completed == true || event.status != 'PENDING' ? 'btn-secondary' : 'btn-danger'"
                                        :disabled="event.email == '' || event.is_completed == true || event.status != 'PENDING'">
                                        <span v-if="event.isRetrying">Verifying...</span>
                                        <span v-else>{{ event.email == '' || event.is_completed == true || event.status !=
                                            'PENDING' ? 'Completed' : 'Incomplete' }}</span>
                                    </button>
                                    <!-- @click="retryNalo(event, i)" -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <center class="mt-5 mb-5">
                <button class="btn btn-success btn-bg" v-show="total > events.length" @click="loadMore">
                    Load More
                    <Spinner v-if="isButtonLoading" />
                </button>
            </center>

            

        </div>

        <NotFound v-else />

        <!-- <pre>{{ verifyData }}</pre> -->




        <!-- <div class="row">
            <div class="col-md-6">
                <pre>{{ events[0] }}</pre>
            </div>
            <div class="col-md-6">
                <pre>{{ events[10] }}</pre>
            </div>
        </div> -->


    </section>
</template>

<script>
import dayjs from 'dayjs';
import PayStack from "paystack";
import { getFunctions, httpsCallable } from '@firebase/functions';
import { collection, getDocs, getFirestore, limit, orderBy, query, startAfter, where, doc, getDoc, increment, updateDoc, setDoc, } from '@firebase/firestore';
import { recalculateTransactions } from '../../services/Events';



const Pay = new PayStack("sk_live_a53679564e88325e9e54fcf766ebb75a7bdab13f");

const db = getFirestore();
const functions = getFunctions();

export default {
    data() {
        return {
            events: [],
            limit: 100,
            isLoading: false,
            isVerifying: false,
            isButtonLoading: false,
            isRecalculating: false,
            isReseting: false,
            lastVisible: null,
            total: 0,
            unsubscribe: null,
            isExporting: false,
            nomineeTotals: [],
            verifyData: {},
            isRecalcTrans: false,
            nomineeId: null,
        }
    },
    mounted() {
        this.getTransactions();
    },
    methods: {
        formatBody(doc) {
            const data = doc.data();
            data.id = doc.id;
            data.created = dayjs(data.created_at.toDate()).format('YYYY-MM-DD HH:mm:ss');
            data.isRetrying = false;
            if ('nominee' in data) {
                const { name } = data.nominee;
                data.nominee_name = name;
            } else {
                data.nominee_name = 'N/A';
            }
            return data;
        },

        add() {
            this.$router.push('/events/add').catch(() => { });
        },
        deleteEvent(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this transaction!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        console.log(id);
                    }
                }).catch((err) => {
                    console.log(err);
                });
        },
        async getTransactions() {
            this.isLoading = true;
            const { eventId } = this.$route.params;
            const eventRef = collection(db, 'transactions');
            const q = query(
                eventRef,
                where('event_id', '==', eventId),
                orderBy('created_at', 'desc'),
                limit(this.limit)
            );
            try {
                const snapshot = await getDocs(q);
                if (snapshot.docs.length > 0) {
                    this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
                    const events = snapshot.docs.map(doc => this.formatBody(doc));
                    this.events = events;
                    this.isLoading = false;
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
            }


        },
        async loadMore() {
            this.isButtonLoading = true;
            const eventId = this.$route.params.eventId;
            const eventRef = collection(db, 'transactions');
            const q = query(
                eventRef,
                where('event_id', '==', eventId),
                orderBy('created_at', 'desc'),
                limit(this.limit),
                startAfter(this.lastVisible)
            );
            const snapshot = await getDocs(q);
            this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
            const events = snapshot.docs.map(doc => this.formatBody(doc));
            this.events = this.events.concat(events);
            this.isButtonLoading = false;
        },
        async totalTransactions() {
            const eventId = this.$route.params.eventId;
            const eventRef = collection(db, 'transactions');
            const q = query(
                eventRef,
                where('event_id', '==', eventId)
            );
            const snapshot = await getDocs(q);
            this.total = snapshot.docs.length;
        },
        exportPerformance() {
            const eventId = this.$route.params.eventId;
            const url = process.env.VUE_APP_EVENT_TRANSACTIONS;
            const path = url + '?eventId=' + eventId
            window.open(path);
        },
        async downloadTransactions() {
            const { eventId } = this.$route.params;
            const emailTransactions = httpsCallable(functions, 'emailTransactions')
            this.isExporting = true
            await emailTransactions({ eventId });
            this.isExporting = false
            this.$toast.success("Sent to your email smartkastghana@gmail.com");
        },
        async onVerifyTransaction(event, index) {

            // const ussdRef = collection(db, 'ussd');
            // const ussd = await getDocs(ussdRef);
            // const ussdData = ussd.docs.length;
            // console.log({ ussdData });
            // // return;

            // check if reference key is in the event object
            if (!('reference' in event)) return;


            // get the transaction reference from the event object
            const { reference, name, vote_type } = event;

            if (vote_type == "DIRECT_VOTE") {
                // 
                console.log(vote_type);
            }

            if (name.toUpperCase() !== "USSD TRANSACTION") {

                console.log(event);


                // verify the transaction using the reference key
                const body = await Pay.transaction.verify(reference);

                // make the status uppercase
                const transactionStatus = body.data.status.toUpperCase();

                // get a reference to the transaction document
                const ref = doc(db, 'transactions', reference);

                // get the transaction document
                const transaction = await getDoc(ref);

                // get the transaction data
                const data = transaction.data();

                // get the event id, amount, type and check if the transaction has already been completed
                const { is_completed, type, event_id, amount } = data;

                // if the transaction has already been completed, return
                if (is_completed) {
                    this.$swal({
                        title: 'Transaction already completed',
                        text: 'This transaction has already been completed',
                        icon: 'success',
                        button: 'Ok',
                        showConfirmButton: true,
                        showCancelButton: true,
                        focusConfirm: true,
                        focusCancel: false,
                        reverseButtons: true
                    });
                    return;
                }

                await updateDoc(ref, {
                    status: transactionStatus,
                    updated_at: dayjs().toDate(),
                });

                this.events[index].status = transactionStatus;

                if (transactionStatus == "SUCCESS") {

                    // get the event document
                    await updateDoc(ref, {
                        is_completed: true
                    });

                    // load the event
                    const eventRef = doc(db, 'events', event_id);

                    const event = await getDoc(eventRef);
                    const eventData = event.data();
                    const { paystack } = eventData;
                    const { percentage_charge } = paystack;
                    const fees = Number(amount * 0.05);
                    const earnings = Number(amount - fees);
                    const their_percentage = Number(percentage_charge);
                    const our_percentage = 100 - Number(percentage_charge);
                    const their_earnings = earnings * (their_percentage / 100);
                    const our_earnings = earnings * (our_percentage / 100);

                    // 5% of the amount is the charge as fees to the organizer
                    // sharable amount is the amount minus the fees
                    // the sub account code from the event

                    const performance_payload = {
                        amount: increment(amount),
                        earnings: increment(earnings),
                        fees: increment(fees),
                        our_earnings: increment(our_earnings),
                        their_earnings: increment(their_earnings),
                        their_percentage,
                        our_percentage,
                    }


                    if (type === 'CONTEST') {
                        const { price } = eventData;
                        const { nomineeId, votes, vote_type, bulk_vote_type } = data;
                        const total_votes = Number(votes);
                        const vote_type_lowercase = type.toLowerCase();
                        const total_earnings_by_vote_type = `total_${vote_type_lowercase}_earnings`;
                        performance_payload.total_votes = increment(total_votes);
                        performance_payload.voters = increment(1);
                        performance_payload[vote_type_lowercase] = increment(total_votes);
                        performance_payload[total_earnings_by_vote_type] = increment(their_earnings);

                        if (vote_type == "BULK_VOTE") {
                            const unitPrice = Number(price);
                            const normal_votes = Math.floor(amount / unitPrice);
                            const promo_votes = total_votes - normal_votes;
                            performance_payload.promo_votes = increment(promo_votes);
                            performance_payload.plan_earnings = {
                                [bulk_vote_type]: increment(their_earnings)
                            };
                            performance_payload.plan_counters = {
                                [bulk_vote_type]: increment(1)
                            }
                            performance_payload.plan_votes = {
                                [bulk_vote_type]: increment(total_votes)
                            }
                        }

                        const nomineeRef = doc(db, 'events', event_id, 'nominees', nomineeId);
                        await setDoc(nomineeRef, { votes: increment(total_votes) }, { merge: true });

                    }

                    // save the performance data
                    const performanceRef = doc(db, 'event_performance', event_id);
                    await setDoc(performanceRef, performance_payload, { merge: true });

                }


                // if (payment_origin == 'local') {
                //     // get the event document
                // } else {
                //     // get the transaction document
                // const { data } = await httpsCallable(functions, 'verifyFlutterwave')({ reference })
                // const { status } = data;
                // console.log(data);
                // if (status == 'error') {
                //     const body1 = await Pay.transaction.verify(reference);
                //     console.log("body1", body1);
                // }
                // }

            }






        },
        async onCheckNomineeCount() {
            const artistsRef = collection(db, 'entries_artistes');
            const albumsRef = collection(db, 'entries_albums');
            const musicVideosRef = collection(db, 'entries_music_videos');

            const artists = await getDocs(artistsRef);
            const albums = await getDocs(albumsRef);
            const musicVideos = await getDocs(musicVideosRef);

            const nominees = [
                {
                    artists: artists.docs.length
                },
                {
                    albums: albums.docs.length
                },
                {
                    musicVideos: musicVideos.docs.length
                },
            ];

            // console.log(nominees);
            this.nomineeTotals = nominees

        },
        async onBulkVote() {
            const transactionsRef = collection(db, 'transactions');
            const w = where('vote_type', '==', 'BULK_VOTE');
            const q = query(transactionsRef, w);
            const transactions = await getDocs(q);
            const transactionsData = transactions.docs
                .map(doc => {
                    const data = doc.data();
                    data.transaction_id = doc.id;
                    return data
                })
                .filter(data => data.status == 'PENDING')
                .map(item => item.transaction_id);

            for (const item of transactionsData) {
                this.$toast.success(`Processing ${item}`);
                // // get a reference to the transaction document
                const ref = doc(db, 'transactions', item);
                await updateDoc(ref, {
                    status: "FAILED",
                    updated_at: dayjs().toDate(),
                    is_completed: true,
                });
            }
            this.$toast.success(`DONE!`);

            // const payload = {
            //     transaction_ids: transactionsData
            // }

            // const verifyRef = collection(db, 'bulk_vote_verify_transactions');
            // await addDoc(verifyRef, payload);

            // this.$toast.success('Bulk vote verification sent');

            // for (const item of transactionsData) {
            //     try {
            //         // const { status, message } = await Pay.transaction.verify(item);
            //         // console.log({ status, message });

            //         const { data } = await httpsCallable(functions, 'verifyFlutterwave')({ item })
            //         console.log({ data });
            //         // this.$toast.error(message);

            //         // if (status === true) {

            //             // this.$toast.success(message);

            //             // // get a reference to the transaction document
            //             // const ref = doc(db, 'transactions', item);

            //             // // get the transaction document
            //             // const transaction = await getDoc(ref);

            //             // // get the transaction data
            //             // const transacData = transaction.data();

            //             // // make the status uppercase
            //             // const transactionStatus = "SUCCESS";

            //             // // get the event id, amount, type and check if the transaction has already been completed
            //             // const { is_completed, type, event_id, amount } = transacData;

            //             // // if the transaction has already been completed, return
            //             // if (is_completed) {
            //             //     this.$swal({
            //             //         title: 'Transaction already completed',
            //             //         text: 'This transaction has already been completed',
            //             //         icon: 'success',
            //             //         button: 'Ok',
            //             //         showConfirmButton: true,
            //             //         showCancelButton: true,
            //             //         focusConfirm: true,
            //             //         focusCancel: false,
            //             //         reverseButtons: true
            //             //     });
            //             //     return;
            //             // }

            //             // await updateDoc(ref, {
            //             //     status: transactionStatus,
            //             //     updated_at: dayjs().toDate(),
            //             // });

            //             // if (transactionStatus == "SUCCESS") {

            //             //     // get the event document
            //             //     await updateDoc(ref, {
            //             //         is_completed: true
            //             //     });

            //             //     // load the event
            //             //     const eventRef = doc(db, 'events', event_id);

            //             //     const event = await getDoc(eventRef);
            //             //     const eventData = event.data();
            //             //     const { paystack } = eventData;
            //             //     const { percentage_charge } = paystack;
            //             //     const fees = Number(amount * 0.05);
            //             //     const earnings = Number(amount - fees);
            //             //     const their_percentage = Number(percentage_charge);
            //             //     const our_percentage = 100 - Number(percentage_charge);
            //             //     const their_earnings = earnings * (their_percentage / 100);
            //             //     const our_earnings = earnings * (our_percentage / 100);

            //             //     // 5% of the amount is the charge as fees to the organizer
            //             //     // sharable amount is the amount minus the fees
            //             //     // the sub account code from the event

            //             //     const performance_payload = {
            //             //         amount: increment(amount),
            //             //         earnings: increment(earnings),
            //             //         fees: increment(fees),
            //             //         our_earnings: increment(our_earnings),
            //             //         their_earnings: increment(their_earnings),
            //             //         their_percentage,
            //             //         our_percentage,
            //             //     }


            //             //     if (type === 'CONTEST') {
            //             //         const { price } = eventData;
            //             //         const { nomineeId, votes, vote_type, bulk_vote_type } = transacData;
            //             //         const total_votes = Number(votes);
            //             //         const vote_type_lowercase = type.toLowerCase();
            //             //         const total_earnings_by_vote_type = `total_${vote_type_lowercase}_earnings`;
            //             //         performance_payload.total_votes = increment(total_votes);
            //             //         performance_payload.voters = increment(1);
            //             //         performance_payload[vote_type_lowercase] = increment(total_votes);
            //             //         performance_payload[total_earnings_by_vote_type] = increment(their_earnings);

            //             //         if (vote_type == "BULK_VOTE") {
            //             //             const unitPrice = Number(price);
            //             //             const normal_votes = Math.floor(amount / unitPrice);
            //             //             const promo_votes = total_votes - normal_votes;
            //             //             performance_payload.promo_votes = increment(promo_votes);
            //             //             performance_payload.plan_earnings = {
            //             //                 [bulk_vote_type]: increment(their_earnings)
            //             //             };
            //             //             performance_payload.plan_counters = {
            //             //                 [bulk_vote_type]: increment(1)
            //             //             }
            //             //             performance_payload.plan_votes = {
            //             //                 [bulk_vote_type]: increment(total_votes)
            //             //             }
            //             //         }

            //             //         const nomineeRef = doc(db, 'events', event_id, 'nominees', nomineeId);
            //             //         await setDoc(nomineeRef, { votes: increment(total_votes) }, { merge: true });

            //             //     }

            //             //     // save the performance data
            //             //     const performanceRef = doc(db, 'event_performance', event_id);
            //             //     await setDoc(performanceRef, performance_payload, { merge: true });

            //             // }

            //         // }
            //     } catch (error) {
            //         console.log(error);
            //     }





            // }

            // console.log({ transactionsData });
        },
        async onAddTransaction() {
            this.$toast.info('Adding transaction...');
            const reference = "6941ba04-3b1d-4785-8e29-8ce1a14e6086"
            const payload = {
                amount: 500,
                bulk_vote_type: "gold",
                created_at: dayjs().toDate(),
                email: "ai17ma17@gmail.com",
                event_date: dayjs("2022-08-31").toDate(),
                event_id: "skKwXevEeIinfqWZHyLw",
                event_title: "TOP MODEL OF THE YEAR AWARDS (TMYAWARDS)",
                isAnonymous: false,
                is_completed: false,
                name: "Mario",
                nomineeId: "zxb6D6UohoSAXNLK1EUx",
                reference,
                status: "PENDING",
                telephone: null,
                type: "CONTEST",
                updated_at: dayjs().toDate(),
                vote_type: "BULK_VOTE",
                votes: 1000,
                nominee: {
                    categoryId: null,
                    eventId: null,
                    name: null,
                    nominee_code: null,
                    photo: null,
                    votes: null,
                }
            }
            const transactionRef = doc(db, 'transactions', reference);
            const transactionDoc = await getDoc(transactionRef);
            if (transactionDoc.exists) {
                this.$toast.error('Transaction already exists');
                return;
            }
            const nomineeRef = doc(db, 'events', payload.event_id, 'nominees', payload.nomineeId);
            const nomineeDoc = await getDoc(nomineeRef);
            if (!nomineeDoc.exists) {
                this.$toast.error('Nominee does not exist');
                return;
            }
            const nomineeData = nomineeDoc.data();
            const { categoryId, eventId, name, nominee_code, photo, votes } = nomineeData;
            payload.nominee = {
                categoryId,
                eventId,
                name,
                nominee_code,
                photo,
                votes,
            }
            await setDoc(transactionRef, payload, { merge: true });
            this.$toast.success("Transaction added successfully: " + reference);
        },

        async onRecalculate() {
            this.isRecalculating = true;
            const { eventId } = this.$route.params;
            const recalcEventRef = doc(db, '1_event_success', eventId);
            await setDoc(recalcEventRef, { event_id: eventId, status: 'initialized', created_at: dayjs().toDate(), }, { merge: true });
            this.$toast.success("Recalculation started");
            this.isRecalculating = false;
        },

        async onTotalTransactions() {
            this.isRecalculating = true;
            const transactionsRef = collection(db, 'transactions');
            const snapshot = await getDocs(transactionsRef);
            const total = snapshot.size;
            console.log(total, dayjs().toDate());
            this.isRecalculating = false;
        },
        async onRestorePerformance() {
            this.isReseting = true;
            const { eventId } = this.$route.params;

            try {
                const restorePerformance = await httpsCallable(functions, 'restorePerformance')({ event_id: eventId })
                console.log(restorePerformance);
            } catch (error) {
                console.log(error);
            }



            // const performanceRef = collection(db, 'event_performance', eventId);
            // const snapshot = await getDocs(performanceRef);
            // const performanceData = snapshot.docs.map(doc => this.formatBody(doc));
            // const performanceRef2 = collection(db, 'event_performance', eventId);
            // await setDocs(performanceRef2, performanceData);
            // this.$toast.success("Performance restored");
            this.isReseting = false;
        },

        async retryNalo(event, i) {

            this.events[i].isRetrying = true;

            const { reference } = event;

            const verifyNaloSolutionsPayment = await httpsCallable(functions, 'verifyNaloSolutionsPayment')({ reference });

            this.verifyData = verifyNaloSolutionsPayment.data;

            this.events[i].isRetrying = false;

            console.log(verifyNaloSolutionsPayment);

        },

        async onRecalcTransaction() {
            // get the event ID from the route
            try {
                this.isRecalcTrans = true;
                const { eventId } = this.$route.params;
                const { status, data, error, groups } = await recalculateTransactions(eventId);
                console.log({ status, data, eventId, error, groups });
            } catch (error) {
                alert(error.message);
            } finally {
                this.isRecalcTrans = false;
            }
        },


        async onVerifyVotes() {
            try {
                this.isVerifying = true;
                const { eventId } = this.$route.params;
                const { nomineeId } = this;

                if (!nomineeId) return;

                const transactionsRef = collection(db, 'transactions');

                const q = query(
                    transactionsRef,
                    where('event_id', '==', eventId),
                    where('nominee_id', '==', nomineeId.trim()),
                    where('status', 'in', ['SUCCESS', 'PAID']),
                );

                const snapshot = await getDocs(q);

                const transactions = snapshot.docs.map(doc => doc.data());

                // sum the votes
                const totalVotes = transactions.reduce((acc, curr) => {
                    return acc + curr.votes;
                }, 0);

                console.log(totalVotes);

                // nominee
                const nomineeRef = doc(db, "events", eventId, 'nominees', nomineeId);
                await updateDoc(nomineeRef, { votes: totalVotes });

                this.nomineeId = null;

            } catch (error) {
                console.log(error);
            } finally {
                this.isVerifying = false;
            }
        },


    },
    beforeDestroy() {
        // this.unsubscribe();
    },
}
</script>