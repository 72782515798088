<template>
    <section class="content">
        <header class="content__title">
            <h1>Users</h1>
            <small>Users information</small>
            <div class="actions">
                <button class="btn btn-success" @click="add">
                    <i class="zmdi zmdi-plus"></i> 
                    Add User
                </button>
            </div>
        </header>
        <Placeholder v-if="isLoading" />
        <div class="contacts row" v-else-if="users.length > 0">
            <div class="col-xl-3 col-lg-3 col-sm-4 col-6" v-for="(user, i) in users" :key="i">
                <div class="contacts__item">
                    <a class="contacts__img">
                        <progressive-img 
                            :src="user.photo"
                            :blur="0"
                            :placeholder="placeholder"
                            :fallback="placeholder"
                            class="contacts__photo" 
                            />
                    </a>
                    <div class="contacts__info">
                        <strong>{{ user.name }}</strong>
                        <small>{{ user.email }}</small>
                        <small>{{ user.type }}</small>
                    </div>
                    <button class="contacts__btn" @click="onDelete(user, i)">Delete</button>
                </div>
            </div>
        </div>
        <NotFound message="No users found" v-else />
    </section>
</template>

<script>
import { collection, deleteDoc, doc, getDocs, getFirestore, query, where } from '@firebase/firestore';
const db = getFirestore();
export default {
    data(){
        return {
            isLoading: false,
            placeholder: '/img/placeholder-square.jpg',
            users: [],
        }
    },
    created(){
        this.getUsers();
    },
    methods: {
        add() {
            this.$router.push('/users/add').catch(() => {});
        },
        onEdit(user){
            const { id } = user;
            this.$router.push('/users/u/' + id + '/edit').catch(() => {});
        },
        onDelete(user, i){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this user!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then( async (result) => {
                if (result.isConfirmed) {
                    this.users.splice(i, 1);
                    const { id } = user;
                    const userRef = doc(db, 'users', id)
                    await deleteDoc(userRef);
                    this.$toast.success('User deleted successfully');
                }
            })
        },
        async getUsers(){
            this.isLoading = true;
            const reference = collection(db, 'users');
            const q = query(reference, where('type', '==', 'administrator'));
            const users = await getDocs(q);
            this.users = users.docs.map(doc => {
                return { id: doc.id, ...doc.data() }
            });
            this.isLoading = false;
        },
        
    }
}
</script>