<template>
    <section class="content">
        <header class="content__title">
            <h1>Events</h1>
            <small>Load all events</small>

            <div class="actions">
                <button class="btn btn-success" @click="add">
                    <i class="zmdi zmdi-plus"></i> 
                    Add Event
                </button>

                <button class="btn btn-danger ml-3" @click="onTesting">
                    Testing
                </button>

            </div>
        </header>

        <Placeholder v-if="isLoading" />

        <div class="row" v-else-if="events.length > 0">
            <div class="col-md-3" v-for="(event, i) in events" :key="i">
                <div class="card">
                    <progressive-img 
                        :src="event.image"
                        :blur="0"
                        :placeholder="placeholder"
                        :fallback="placeholder"
                        class="img-fluid" 
                        />
                    <div class="card-body">
                        <h4 class="card-title">{{ event.title }}</h4>
                        <h6 class="card-subtitle">{{ event.event_date }}</h6>
                        <h6 class="card-subtitle">{{ event.type }}</h6>
                    </div>
                    <div class="card-footer">
                        <button class="btn" @click="view(event.id)">
                            View
                        </button>
                        <button class="btn" @click="edit(event.id)">
                            Edit
                        </button>
                        <button class="btn" @click="report(event.id)">
                            Report
                        </button>
                        <button class="btn" @click="transactions(event.id)">
                            Transactions
                        </button>
                        <button class="btn" @click="deleteEvent(event.id, i)">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <NotFound message="No events found" v-else />

    </section>
</template>

<script>
import { collection, deleteDoc, doc, getDocs, getFirestore, orderBy, query, getDoc } from '@firebase/firestore';
import dayjs from 'dayjs';
const db = getFirestore();
export default {
    data(){
        return {
            events: [],
            isLoading: false,
            placeholder: '/img/placeholder.jpg',
            per_page: 15,
            unsubscribe: null,
            done: 0,
        }
    },
    mounted(){
        this.loadEvents();
    },
    methods: {
        add(){
            this.$router.push('/events/add').catch(() => {});
        },
        view(id){
            this.$router.push('/events/e/' + id).catch(() => {});
        },
        edit(id){
            this.$router.push('/events/e/' + id + '/edit').catch(() => {});
        },
        report(id){
            this.$router.push('/events/e/' + id + '/report').catch(() => {});
        },
        transactions(id){
            this.$router.push('/events/e/' + id + '/transactions').catch(() => {});
        },
        deleteEvent(id, i){
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this transaction!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                reverseButtons: true
            })
            .then( async (result) => {
                if (result.isConfirmed) {
                    this.events.splice(i, 1);
                    this.$toast.success('Event deleted successfully');
                    const ref = doc(db, 'events', id);
                    await deleteDoc(ref);
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        async loadEvents(){
            this.isLoading = true;
            const reference =  collection(db, 'events');
            const q = query(reference, orderBy('created_at', 'desc'));
            const snapshot = await getDocs(q);
            const events = snapshot.docs.map(doc => {
                const data = doc.data();
                data.id = doc.id;
                data.event_date = dayjs(data.date.toDate()).format('MMMM D YYYY, h:mm:ss a');
                return data;
            });
            this.events = events;
            this.isLoading = false;
        },

        async onTesting() {

            const event_id = 'C0BpwskkI4P2Eeok6sbb';

            const reference =  doc(db, 'event_performance', event_id);
            // const o =orderBy('created_at', 'desc');
            const q = query(reference);
            const snapshot = await getDoc(q);
            const eventIds = snapshot.data();
            // const eventIds = snapshot.docs.map(doc => doc.id);

            console.log(eventIds);

            return;

        }
    }
}
</script>