<template>
    <section class="content">
        <header class="content__title">
            <h1>Settings</h1>
            <small>Fine tune the application here</small>
            <div class="actions">
            </div>
        </header>
        <div class="card">
            <div class="card-header">
                Recalculate Event Performance
            </div>
            <div class="card-body">
                <form @submit.prevent="onSave">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Events</label>
                                <Multiselect 
                                    v-model="value" 
                                    :options="events" 
                                    @select="onSelectEvent"
                                    placeholder="Select events"
                                    label="title"
                                    track-by="id"
                                    />
                            </div>
                            <div class="form-group">
                                <button class="btn btn-success" :disabled="isProcessing">
                                    <i class="zmdi zmdi-refresh"></i>
                                    Recalculate
                                    <Spinner v-if="isProcessing" />
                                </button>
                                <button @click="verifyNalo" class="btn">
                                    verifyNalo
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6"></div>
                    </div>
                </form>
            </div>
            
            <pre>{{ count_down }}</pre>
            <!-- <pre>{{ value }}</pre> -->
            <!-- <pre>{{ events }}</pre> --> 
        </div>
    </section>
</template>

<script>
import dayjs from 'dayjs';
import { loadEvents, addRecalculatePerformance, verify_nalo_solutions_v2 } from '../../services/Events';
import { setDoc, doc, getFirestore } from '@firebase/firestore';
const firestore = getFirestore();

export default {
    data(){
        return {
            events: [],
            value: null,
            isProcessing: false,
            event: {
                id: null,
                title: null,
                type: null,
                go_live: null,
                enable_bulk_votes: false,
                percentage_charge: null,
                price: null,
                bulk_vote_plans: [],
                created_at: null,
                is_done: false,
                is_recalculating: null,
                recalculate_start: null,
                recalculate_end: null,
            },
            count_down: 0,
        }
    },
    methods: {
        onSelectEvent(item){
            this.event = {};
            const { id, price, paystack, title, type, bulk_vote_plans, enable_bulk_votes, unique_id, } = item;
            const { percentage_charge } = paystack;
            const payload = { id, price, percentage_charge, title, type, enable_bulk_votes, unique_id, }
            for (const key in payload) {
                this.event[key] = payload[key];
            }
            if(enable_bulk_votes){
                this.event.bulk_vote_plans = bulk_vote_plans;
            } else {
                this.event.bulk_vote_plans = [];
            }
            this.event.created_at =  null;
            this.event.is_done =  false;
            this.event.is_recalculating =  false;
            this.event.recalculate_start =  null;
            this.event.recalculate_end =  null;
        },
        async onLoadEvents(){
            const { status, data } = await loadEvents();
            if(status){
                this.events = data;
            }
        },
        async onSave(){

            this.event.created_at = dayjs().toDate();
            
            const { value, event } = this;
            
            if (value !== null) {
                try {
                    this.isProcessing = true;
                    const { status, message } = await addRecalculatePerformance(event)
                    this.$toast[status ? 'success' : 'error'](message);
                    if(status){
                        this.value = null
                        this.event = {
                            id: null,
                            title: null,
                            go_live: null,
                            enable_bulk_votes: false,
                            created_at: null,
                            is_recalculating: false,
                            is_done: false,
                            recalculate_start: null,
                            recalculate_end: null,
                        }
                    }
                    this.isProcessing = false;
                } catch (error) {
                    this.isProcessing = false;
                    this.$toast.error(error.message);
                }
            }
        },
        async verifyNalo(){
            const { status, data } = await verify_nalo_solutions_v2();
            if(status){
                // this.count_down = total;
                for (const id of data) {
                    this.count_down++;
                    const docId = doc(firestore, 'transactions', id)
                    const payload = { gateway: "NALOSOLUTIONS" }
                    await setDoc(docId, payload, { merge: true });
                    console.log("NALOSOLUTIONS", id);
                }
            }
        }
    },
    created(){
        this.onLoadEvents();
    },
}
</script>