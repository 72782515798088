<template>
    <section class="content">
        <header class="content__title">
            <h1>Dashboard</h1>
            <small>Welcome to the SmartKast!</small>
            <div class="actions">
                <!-- <button class="btn btn-danger" @click="onAction">Total Telephone</button> -->
            </div>
        </header>

        <div class="row quick-stats">
            <div class="col-sm-6 col-md-2">
                <div class="quick-stats__item bg-blue">
                    <div class="quick-stats__info">
                        <h2>{{ analytics.events || 0 }}</h2>
                        <small>Total Events</small>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-2">
                <div class="quick-stats__item bg-amber">
                    <div class="quick-stats__info">
                        <h2>{{ analytics.contest || 0 }}</h2>
                        <small>Total Contests</small>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-2">
                <div class="quick-stats__item bg-purple">
                    <div class="quick-stats__info">
                        <h2>{{ analytics.fundraiser || 0 }}</h2>
                        <small>Total Fundraisers</small>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-2">
                <div class="quick-stats__item bg-red">
                    <div class="quick-stats__info">
                        <h2>{{ analytics.organizers || 0 }}</h2>
                        <small>Total Organizers</small>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-2">
                <div class="quick-stats__item bg-green">
                    <div class="quick-stats__info">
                        <h2>{{ analytics.users || 0 }}</h2>
                        <small>Total Users</small>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-2">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">GHS {{ performance.amount }}</h4>
                        <h6 class="card-subtitle">Total Revenue</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">GHS {{ performance.fees }}</h4>
                        <h6 class="card-subtitle">Telco Charges</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            GHS {{ performance.earnings }}
                        </h4>
                        <h6 class="card-subtitle">Revenue</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            GHS {{ performance.their_earnings }}
                        </h4>
                        <h6 class="card-subtitle">Organizers' Revenue</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            GHS {{ performance.our_earnings }}
                        </h4>
                        <h6 class="card-subtitle">SmartKast Revenue</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Events</h4>
                        <h6 class="card-subtitle">Types of events</h6>
                        <v-frappe-chart type="pie" :labels="['Fundraiser', 'Contents']" :data="chartEvents" :tooltipOptions="{
                            formatTooltipX: (d) => (d + '').toUpperCase(),
                            formatTooltipY: (d) => d.toFixed(2) + '%',
                        }" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Events</h4>
                        <h6 class="card-subtitle">Types of events</h6>
                        <v-frappe-chart type="bar" :labels="[
                            'Sun',
                            'Mon',
                            'Tue',
                            'Wed',
                            'Thu',
                            'Fri',
                            'Sat',
                            'Sun',
                        ]" :data="[
    { values: [18, 40, 30, 35, 8, 52, 17, -4] },
]" :colors="['red']" />
                    </div>
                </div>
            </div>
        </div>

        <!-- <pre>{{ chartEvents }}</pre> -->
    </section>
</template>

<script>
import { collection, doc, getDoc, getDocs, getFirestore, getCountFromServer } from "@firebase/firestore";
const db = getFirestore();
export default {
    // name: "DashboardIndex",
    data() {
        return {
            analytics: {
                contest: 0,
                events: 0,
                fundraiser: 0,
                organizers: 0,
            },
            performance: {
                amount: 0,
                fees: 0,
                earnings: 0,
                their_earnings: 0,
                our_earnings: 0,
            },
            isLoading: false,
        };
    },
    created() {
        this.getAnalytics();
        this.getTotalEventPerformance();
    },
    computed: {
        chartEvents() {
            const analytics = this.analytics;
            const { events, fundraiser, contest } = analytics;
            const fundraiserPercent = (fundraiser / events) * 100;
            const contentPercentage = (contest / events) * 100;
            return [
                {
                    values: [fundraiserPercent, contentPercentage],
                },
            ];
        },
    },
    methods: {
        async getAnalytics() {
            this.isLoading = true;
            const ref = doc(db, "analytics", "totals");
            const snapshot = await getDoc(ref);
            const data = snapshot.data();
            this.analytics = data;
            this.isLoading = false;
        },
        async getTotalEventPerformance() {
            const ref = collection(db, "event_performance");
            const snapshot = await getDocs(ref);
            const data = snapshot.docs.map((doc) => doc.data());
            const performance = data.reduce(
                (acc, curr) => {
                    acc.amount += curr.amount;
                    acc.fees += curr.fees;
                    acc.earnings += curr.earnings;
                    acc.their_earnings += curr.their_earnings;
                    acc.our_earnings += curr.our_earnings;
                    return acc;
                },
                {
                    amount: 0,
                    fees: 0,
                    earnings: 0,
                    their_earnings: 0,
                    our_earnings: 0,
                }
            );

            for (const item in performance) {
                this.performance[item] = performance[item].toFixed(2);
            }
        },
        async onAction() {
            try {
                console.log(`============START===========`);
                let collectionRef = collection(db, "ussd");
                const snapshot = await getCountFromServer(collectionRef);
                let total = snapshot.data().count;
                console.log(total);
                console.log(`============END===========`);
            } catch (error) {
                console.log(`============ERROR===========`);
                console.log(error);
            }
        }
    },

};
</script>