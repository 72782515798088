<template>
    <section class="content">
        <header class="content__title">
            <h1>Event Report</h1>
            <small>A breakdown of the performance of the event</small>
            <div class="actions">
                
                <button class="btn btn-primary" @click="exportPerformance">Export to Excel</button>
                <button class="btn btn-secondary ml-2" @click="onResetVotes">Reset Votes</button>

                <!-- <button class="btn btn-secondary ml-2" @click="onRecalculateTransactions">Transacs Recalc</button>  -->

            </div>
        </header>

        <ToolbarEvents />

        <Placeholder v-if="is_loading" />

        <div v-else class="row report_cards">

            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.amount }}</div>
                    <div class="title">Total Revenue [ Bulk + Direct ] </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.fees }}</div>
                    <div class="title">Total Fees [ 5% of Revenue ]</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.earnings }}</div>
                    <div class="title">Total Earnings [ Revenue - Fees ]</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.our_earnings }}</div>
                    <div class="title">Our Earnings [ Earnings * (100 - Percentage) ]</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.their_earnings }}</div>
                    <div class="title">Their Earnings [ Earnings * Percentage ]</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.withdrawals || 0 }}</div>
                    <div class="title">Total Withdrawals</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.ourstanding_earnings || 0 }}</div>
                    <div class="title">Total Outstanding</div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.percentages?.organizer }}%</div>
                    <div class="title">Organizer's Percentage</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.percentages?.smartkast }}%</div>
                    <div class="title">Smartkast's Percentage</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.total_bulk_vote_earnings }}</div>
                    <div class="title">Total Bulk Vote Earnings</div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.total_direct_vote_earnings }}</div>
                    <div class="title">Total Direct Vote Earnings</div>
                </div>
            </div> -->
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.voters }}</div>
                    <div class="title">Total Voters</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.plan_earnings?.gold }}</div>
                    <div class="title">Total Gold Bulk Earnings</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.plan_earnings?.silver }}</div>
                    <div class="title">Total Bronze Silver Earnings</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.plan_earnings?.bronze }}</div>
                    <div class="title">Total Bronze Bulk Earnings</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.plan_prices?.gold }}</div>
                    <div class="title">Gold Bulk Price</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.plan_prices?.silver }}</div>
                    <div class="title">Silver Bulk Price</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">GHS {{ performance?.plan_prices?.bronze }}</div>
                    <div class="title">Bronze Bulk Price</div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.total_votes }}</div>
                    <div class="title">Total Votes [ Bulk + Direct ]</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ (performance?.promo_votes || 0) }}</div>
                    <div class="title">Promo Votes</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.direct_vote }}</div>
                    <div class="title">Direct Votes</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.bulk_vote }}</div>
                    <div class="title">Bulk Votes</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.plan_votes?.gold }}</div>
                    <div class="title">Total Gold Bulk Votes</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.plan_votes?.silver }}</div>
                    <div class="title">Total Silver Bulk Votes</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.plan_votes?.bronze }}</div>
                    <div class="title">Total Bronze Bulk Votes</div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.plan_counters?.gold }}</div>
                    <div class="title">Total Gold Bulk Voters</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.plan_counters?.silver }}</div>
                    <div class="title">Total Silver Bulk Voters</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="item">
                    <div class="value">{{ performance?.plan_counters?.bronze }}</div>
                    <div class="title">Total Bronze Bulk Voters</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { doc, getDoc, getFirestore, onSnapshot } from '@firebase/firestore';
import sweetalert2 from 'sweetalert2';
import { resetVotes, recalculateTransactions, eventPerformance } from '../../services/Events';
const db = getFirestore();
export default {
    data() {
        return {
            performance: {
                bulk_vote: 0,
                direct_vote: 0,
                total_votes: 0,
                voters: 0,
                earnings: 0,
                total_bulk_vote_earnings: 0,
                total_direct_vote_earnings: 0,
                their_earnings: 0,
                amount: 0,
                fees: 0,
                our_earnings: 0,
                plan_earnings: {
                    bronze: 0,
                    silver: 0,
                    gold: 0,
                },
                plan_counters: {
                    bronze: 0,
                    silver: 0,
                    gold: 0,
                },
                plan_votes: {
                    bronze: 0,
                    silver: 0,
                    gold: 0,
                },
                plan_prices: {
                    bronze: 0,
                    silver: 0,
                    gold: 0,
                },
                percentages: {
                    organizer: 0,
                    smartkast: 0,
                },
                promo_votes: 0,
            },
            unsubscribe: null,
            is_loading: false,
        }
    },
    created() {
        // this.getEventPerformance();
        this.onLoad();
        this.getBulkPlans();
    },
    methods: {
        getEventPerformance() {
            this.is_loading = true;
            const eventId = this.$route.params.eventId;
            const ref = doc(db, 'event_performance', eventId);
            this.unsubscribe = onSnapshot(ref, event => {
                const data = event.data();
                const non_fixed = ['bulk_vote', 'direct_vote', 'total_votes', 'voters', 'promo_votes'];
                const plans = ['bronze', 'silver', 'gold'];
                for (const key in data) {
                    if (typeof data[key] !== 'object') {
                        if (!(non_fixed.includes(key))) {
                            this.performance[key] = typeof data[key] != 'undefined' ? data[key].toFixed(2) : 0;
                        }
                        non_fixed.map(key => {
                            this.performance[key] = typeof data[key] != 'undefined' ? data[key] : 0;
                        });
                    } else {

                        if (key == 'plan_earnings') {
                            plans.map(plan => {
                                this.performance[key][plan] = typeof data[key][plan] != 'undefined' ? data[key][plan].toFixed(2) : 0;
                            });
                        }

                        if (key == 'plan_counters') {
                            plans.map(plan => {
                                this.performance[key][plan] = typeof data[key][plan] != 'undefined' ? data[key][plan] : 0;
                            });
                        }

                        if (key == 'plan_votes') {
                            plans.map(plan => {
                                this.performance[key][plan] = typeof data[key][plan] != 'undefined' ? data[key][plan] : 0;
                            });
                        }

                    }
                }

                // if (!('plan_earnings' in data)) {
                //     this.performance.plan_earnings = {
                //         bronze: 0,
                //         silver: 0,
                //         gold: 0,
                //     }
                // }

                // if (!('plan_counters' in data)) {
                //     this.performance.plan_counters = {
                //         bronze: 0,
                //         silver: 0,
                //         gold: 0,
                //     }
                // }

                this.is_loading = false;

            });
        },
        exportPerformance() {
            const eventId = this.$route.params.eventId;
            const url = process.env.VUE_APP_EVENT_PERFORMANCE;
            const path = url + '?eventId=' + eventId
            window.open(path);
        },
        async getBulkPlans() {
            const eventId = this.$route.params.eventId;
            const ref = doc(db, 'events', eventId);
            const snap = await getDoc(ref);
            const data = snap.data();
            const { bulk_vote_plans, paystack } = data;
            const { percentage_charge } = paystack;
            this.performance.plan_prices = {
                bronze: bulk_vote_plans.find(plan => plan.name.toLowerCase() == 'bronze').amount || 0,
                silver: bulk_vote_plans.find(plan => plan.name.toLowerCase() == 'silver').amount || 0,
                gold: bulk_vote_plans.find(plan => plan.name.toLowerCase() == 'gold').amount || 0,
            }
            this.performance.percentages = {
                organizer: percentage_charge || 0,
                smartkast: 100 - percentage_charge || 0,
            }
        },

        async onResetVotes() {

            const eventId = this.$route.params.eventId;

            sweetalert2.fire({
                icon: "warning",
                title: "RESET VOTES?",
                text: "You are about to reset all votes for nominees in this event. This action cannot be undone. Are you sure you want to proceed?",
                showCancelButton: true,
                confirmButtonText: "Yes, Reset",
                cancelButtonText: "No, Cancel",
                reverseButtons: false,
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {

                    const { status, data, message } = await resetVotes(eventId);

                    console.log({ status, data, message });

                }
            })
        },

        async onRecalculateTransactions() {
            try {
                const eventId = this.$route.params.eventId;
                const { data } = await recalculateTransactions(eventId);
                console.log(data);

            } catch (error) {
                console.log(error);
            }
        },

        async onLoad() {
            try {
                console.log("====== LOADING ======");
                const eventId = this.$route.params.eventId;
                const { data } = await eventPerformance(eventId);
                this.performance = data;
                console.log(data);
                console.log("====== LOADED ======");
            } catch (error) {
                console.log(error);
            }
        }
    },
    beforeDestroy() {
        if (this.unsubscribe != null) {
            this.unsubscribe();
        }
    },
}
</script>